<template>
  <div class="columns is-variable is-2">
    <div class="column is-8-fullhd">
      <div
        v-if="myData.domainId == 1"
        class="has-background-await p-1 pr-2 mb-3"
        style="border-radius: 8px;"
      >
        <b-message
          type="is-await"
          has-icon
          icon="shield-lock-outline"
        >
          <p class="title is-5 mb-4">Uwierzytelnianie dwuskładnikowe</p>
          <p class="mb-3">
            Od
            <b>20 sierpnia</b> konta wszystkich pracowników Centrum Medyczno Diagnostycznego (CMD) mają włączoną
            <b>weryfikację dwuskładnikową</b> (dwuetapową), która podnosi bezpieczeństwo. Logowanie do systemu wymaga kodu jednorazowego, który wysyłany jest w wiadomości e-mail oraz ewentualnie w wiadomości SMS.
          </p>
          <p class="mb-3">
            Aby otrzymywać kod logowania także drogą telefoniczną w wiadomości SMS, prosimy o sprawdzenie swojego numeru telefonu w
            <a @click="proceedTo('my-account')">panelu Moje konto</a>, klikając przycisk "Edytuj dane kontaktowe", a następnie zaznaczenie opcji
            <i>"Oświadczam, że wyrażam dobrowolną zgodę Digitmed SA na użycie numeru telefonu w celu weryfikacji dwuskładnikowej przy logowaniu do systemów"</i>.
          </p>
          <p class="mb-3">W ciągu dnia, między godzinami 6:00 a 18:00, kod jednorazowy będzie wymagany co 6 godzin. Czyli logując się do systemu o 9:00, kolejny kod będzie wymagany dopiero przy logowaniu po godzinie 15:00. W przypadku próby logowania z innego komputera, sieci lub innej przeglądarki, kod bedzie ponownie wymagany.</p>
          <p class="mb-3">Po godzinie 18:00, aż do 6:00 kolejnego dnia, kod jednorazowy będzie wymagany przy każdym logowaniu.</p>
        </b-message>
      </div>
      <ContentPanel
        icon="comment-edit"
        title="Zmiany i poprawki"
        subtitle="Krótkie notatki programistów dot. poprawek i nowych funkcjonalnosci"
      >
        <template slot="addon">
          <b-button
            icon-left="information-outline"
            size="is-small"
            type="is-primary"
            @click="mDownloadInstruction('649ac2dc985c5a0bf2564d55')"
          >Zobacz instrukcję wysyłania zgłoszenia do działu IT</b-button>
        </template>
        <Changelog />
      </ContentPanel>
    </div>
    <div class="column">
      <ContentPanel
        icon="view-dashboard"
        title="SZOK"
        subtitle="System Zarządzania Opieką Koordynowaną"
      >
        <template slot="addon">
          <div
            style="width:10rem;"
            class="pt-1 pr-1"
          >
            <img src="logo-basic.svg" />
          </div>
        </template>
        <b-message
          type="is-primary"
          has-icon
          icon="human-greeting"
        >
          <p class="subtitle is-4 pt-1">
            Witamy w systemie
            <b class="has-text-primary">SZOK</b>
          </p>
        </b-message>

        <table>
          <tr>
            <td class="pr-5 is-size-7">Zalogowano jako:</td>
            <td>
              <span class="has-text-weight-semibold">{{myId}}</span>
            </td>
          </tr>
          <tr>
            <td class="is-size-7">Organizacja:</td>
            <td>
              <span class="has-text-weight-semibold">{{ mGetDomainDesc(clinic.clinicId) }}</span>
            </td>
          </tr>
          <tr>
            <td class="pr-3 is-size-7">Wersja systemu:</td>
            <td>
              <span>{{version}}</span>
            </td>
          </tr>
          <tr>
            <td class="pr-3 is-size-7">Opublikowano:</td>
            <td>{{appdate}}</td>
          </tr>
        </table>
      </ContentPanel>
      <MyAccountApKolceBaner v-if="isMedicalAdmission" />
      <ELearningDashboardCard v-if="perms.eLearning" />
    </div>
    <!-- <GdprModalCheck /> -->
  </div>
</template>

<script>
//import GoalsDashboardCard from '@/components/user-helpers/goals/GoalsDashboardCard'
// import ContentPanel from '@/components/tools/ContentPanel'
import ELearningDashboardCard from "@/components/e-learning/ELearningDashboadrCard";
import Changelog from "@/changelog/ChangelogPanel";
import DomainMixins from "../../mixins/domains";
import { APP_VERSION } from "@/store/config_apis";
import { APP_DATE } from "@/store/config_apis";
import CommonMixins from "../../mixins/commons";
import MyAccountApKolceBaner from "@/components/my-account/MyAccountApKolceBaner";
//import QuickAccess from '@/components/navigation/QuickAccess'
// import GdprModalCheck from '@/components/tools/GdprModalCheck'

export default {
  name: "HomeIndexView",

  components: {
    ELearningDashboardCard,
    Changelog,
    MyAccountApKolceBaner,
    //  ContentPanel,
    // GdprModalCheck
  },

  mixins: [DomainMixins, CommonMixins],

  mounted() {
    if (this.tpk) {
      this.$router.push({ name: "test-office" });
    }
  },

  data: function () {
    return {
      version: APP_VERSION,
      appdate: APP_DATE,
    };
  },

  computed: {
    clinic() {
      return this.$store.state.clinic.activeClinic;
    },

    myId() {
      if (this.myData) {
        return `${this.myData.firstName} ${this.myData.lastName}`;
      } else {
        return this.email;
      }
    },
    email: function () {
      return this.$store.state.identity.session.email;
    },
    myData() {
      return this.$store.state.employee.me;
    },
    inUserAuthorized() {
      return this.$store.state.isUserAuthorized;
    },
    tpk() {
      return this.$store.state.tpk;
    },
    subadmin() {
      return this.$store.state.employee.permits.subadmin;
    },
    perms() {
      return this.$store.state.employee.permits;
    },

    myPositions() {
      return this.$store.state.employeePosition.myPositions ?? [];
    },

    isMedicalAdmission() {
      if (this.myPositions && this.myPositions.length > 0) {
        let found = this.myPositions.find(
          (p) =>
            p.isActive &&
            p.typeObject != null &&
            p.typeObject.name != null &&
            (p.typeObject.name.toLowerCase() == "rejestratorka medyczna" ||
              p.typeObject.name.toLowerCase().includes("call center"))
        );
        if (found) {
          return true;
        }
      }

      return false;
    },
  },

  methods: {
    /**
     * Proceeds to specified location.
     * @param name Target view.
     */
    proceedTo(name) {
      this.$router.push({ name: name });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>