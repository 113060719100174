export const Mutation = {

    TELE_SWITCH: 'TELE_SWITCH',
    SET_DEVICE_ID: 'SET_DEVICE_ID',
    INITIAL_LOADING: 'INITIAL_LOADING',
    SET_RELOAD_PENDING: 'SET_RELOAD_PENDING',

    LOCALS_SCHEDULE_APP_TYPE_VISIBIILITY: 'LOCALS_SCHEDULE_APP_TYPE_VISIBIILITY',

    // PATIENT MODULE
    PATIENT_UPDATE_ALLERGIES: "PATIENT_UPDATE_ALLERGIES",
    PATIENT_SET_PATIENTS: "PATIENT_SET_PATIENTS",
    PATIENT_SET_TELEINFORMATION: "PATIENT_SET_TELEINFORMATION",
    PATIENT_SET_MEDICAL_INFORMATION: "PATIENT_SET_MEDICAL_INFORMATION",
    PATIENT_ADD_ALLERGY: 'PATIENT_ADD_ALLERGY',
    PATIENT_REMOVE_ALLERGY: 'PATIENT_REMOVE_ALLERGY',
    PATIENT_SET_DECLARATIONS: 'PATIENT_SET_DECLARATIONS',
    PATIENT_SET_PROGRAMS: 'PATIENT_SET_PROGRAMS',

    // ORDERS MODULE
    ORDERS_SET_ORDERS: "ORDERS_SET_ORDERS",
    ORDERS_DELETE_ORDER: "ORDERS_DELETE_ORDER",
    ORDERS_SET_PRODUCTS: "ORDERS_SET_PRODUCTS",
    ORDERS_ADD_PRODUCT: "ORDERS_ADD_PRODUCT",
    ORDERS_REMOVE_PRODUCT: "ORDERS_REMOVE_PRODUCT",
    ORDERS_MODIFY_PRODUCT: "ORDERS_MODIFY_PRODUCT",
    ORDERS_SET_SUPPLIERS: "ORDERS_SET_SUPPLIERS",
    ORDERS_ADD_SUPPLIER: "ORDERS_ADD_SUPPLIER",
    ORDERS_REMOVE_SUPPLIER: "ORDERS_REMOVE_SUPPLIER",
    ORDERS_MODIFY_SUPPLIER: "ORDERS_MODIFY_SUPPLIER",
    ORDERS_RESET: "ORDERS_RESET",

    // EDU MODULE
    EDU_SET_COURSES: "EDU_SET_COURSES",
    EDU_SET_PROGRESS: "EDU_SET_PROGRESS",
    EDU_ADD_COURSE_M: "EDU_ADD_COURSE_M",

    // MOBI MODULE
    MOBI_SET_MY_ACCOUNT: "MOBI_SET_MY_ACCOUNT",
    MOBI_SET_ACCOUNTS: "MOBI_SET_ACCOUNTS",
    MOBI_SET_ACCOUNT: "MOBI_SET_ACCOUNT",
    MOBI_SET_USERS: "MOBI_SET_USERS",
    MOBI_ADD_USER: "MOBI_ADD_USER",
    MOBI_SET_EMPLOYEE_ACCOUNT: 'MOBI_SET_EMPLOYEE_ACCOUNT',

    MOBI_SET_PARCELS: "MOBI_SET_PARCELS",
    MOBI_ADD_PARCEL: "MOBI_ADD_PARCEL",
    MOBI_ADD_PARCELS: "MOBI_ADD_PARCELS",
    MOBI_MODIFY_PARCEL: "MOBI_MODIFY_PARCEL",
    MOBI_MODIFY_PARCEL_DIRECT: "MOBI_MODIFY_PARCEL_DIRECT",
    MOBI_RESET: 'MOBI_RESET',

    MOBI_SET_VEHICLES: "MOBI_SET_VEHICLES",
    MOBI_ADD_VEHICLE: "MOBI_ADD_VEHICLE",
    MOBI_REMOVE_VEHICLE: "MOBI_REMOVE_VEHICLE",
    MOBI_EDIT_VEHICLE: "MOBI_EDIT_VEHICLE",
    MOBI_CHANGE_PLACE: "MOBI_CHANGE_PLACE",

    MOBI_SET_ALL_ROUTES: "MOBI_SET_ALL_ROUTES",
    MOBI_ADD_ROUTE: "MOBI_ADD_ROUTE",
    MOBI_REMOVE_ROUTE: "MOBI_REMOVE_ROUTE",
    MOBI_EDIT_ROUTE: "MOBI_EDIT_ROUTE",


    DEKLE_SET_FOCUS: "DEKLE_SET_FOCUS",

    // LAB
    LAB_SEND_CONTAINER: 'LAB_SEND_CONTAINER',
    LAB_POP_CONTAINER: 'LAB_POP_CONTAINER',

    LAB_SEND_EDM: 'LAB_SEND_EDM',
    LAB_EDM_TRIGGER_UP: 'LAB_EDM_TRIGGER_UP',
    LAB_EDM_REMOVE_REQUEST: 'LAB_EDM_REMOVE_REQUEST',

    LAB_SET_FACILITY: 'LAB_SET_FACILITY',
    LAB_SET_OFFICE: 'LAB_SET_OFFICE',
    LAB_SET_SUBUNIT: 'LAB_SET_SUBUNIT',
    LAB_SET_WORKER: 'LAB_SET_WORKER',

    LAB_PATIENT_HISTORY_ADD: 'LAB_PATIENT_HISTORY_ADD',

    // POZ MODULE
    POZ_SET_DASHBOARD_LOADING: "POZ_SET_DASHBOARD_LOADING",

    POZ_MAIN_DIAGNOSE: "POZ_MAIN_DIAGNOSE",
    POZ_ADD_EXAMINATION: "POZ_ADD_EXAMINATION",
    POZ_REMOVE_EXAMINATION: "POZ_REMOVE_EXAMINATION",
    POZ_RESET_EXAMINATIONS: "POZ_RESET_EXAMINATIONS",
    POZ_CLEAR_ONGOING_APPOINTMENT: "POZ_CLEAR_ONGOING_APPOINTMENT",
    POZ_SET_ONGOING_APPOINTMENT: "POZ_SET_ONGOING_APPOINTMENT",
    POZ_SET_APPOINTMENT_HISTORY: "POZ_SET_APPOINTMENT_HISTORY",
    POZ_SET_APPOINTMENT_DIAGNOSES_INITIAL: "POZ_SET_APPOINTMENT_DIAGNOSES_INITIAL",
    POZ_SET_APPOINTMENT_INITIAL_DATA: "POZ_SET_APPOINTMENT_INITIAL_DATA",
    POZ_SET_FOCUS_APPOINTMENT_FROM_LIST: "POZ_SET_FOCUS_APPOINTMENT_FROM_LIST",
    POZ_SET_FOCUS_APPOINTMENT: "POZ_SET_FOCUS_APPOINTMENT",
    POZ_SET_APPOINTMENTS: "POZ_SET_APPOINTMENTS",
    POZ_SET_PATIENTS: "POZ_SET_PATIENTS",
    POZ_RESET: 'POZ_RESET',
    POZ_TMP_SAVE: 'POZ_TMP_SAVE',
    POZ_SET_PATHS: 'POZ_SET_PATHS',
    POZ_ADD_PATH_M: "POZ_ADD_PATH_M",
    POZ_SET_PATIENT_DIAGNOSES: "POZ_SET_PATIENT_DIAGNOSES",
    POZ_SET_PATIENT_APPOINTMENTS: "POZ_SET_PATIENT_APPOINTMENTS",
    POZ_ADD_DIAGNOSE: 'POZ_ADD_DIAGNOSE',
    POZ_REFRESH_EXISTING_DIAGNOSE: 'POZ_REFRESH_EXISTING_DIAGNOSE',
    POZ_REMOVE_DIAGNOSE: 'POZ_REMOVE_DIAGNOSE',
    PATIENT_SET_TICKETS: 'PATIENT_SET_TICKETS',
    PATIENT_ADD_TICKET: 'PATIENT_ADD_TICKET',
    PATIENT_SET_TICKET_STATUS: 'PATIENT_SET_TICKET_STATUS',
    APPOINTMENT_SIMPLE_FOCUS: 'APPOINTMENT_SIMPLE_FOCUS',
    APPOINTMENT_WORKTIME_FOCUS: 'APPOINTMENT_WORKTIME_FOCUS',
    POZ_SET_FINISH_OBJECT: 'POZ_SET_FINISH_OBJECT',
    POZ_CLEAR_FINISH_OBJECT: 'POZ_CLEAR_FINISH_OBJECT',
    POZ_SET_FINISH_SCHEDULE_OBJECT: 'POZ_SET_FINISH_SCHEDULE_OBJECT',
    POZ_SET_P12_OBJECT: 'POZ_SET_P12_OBJECT',
    POZ_SET_DILO: 'POZ_SET_DILO',

    PRIVILEGES_SET_ALL: 'PRIVILEGES_SET_ALL',
    POZ_DRUG_ADDED: 'POZ_DRUG_ADDED',

    POZ_SET_PRESCRIPTIONS_HISTORY_P1_REQUEST: 'POZ_SET_PRESCRIPTIONS_HISTORY_P1_REQUEST',
    POZ_SET_PRESCRIPTIONS_HISTORY_P1_LOADING: 'POZ_SET_PRESCRIPTIONS_HISTORY_P1_LOADING',
    POZ_SET_PRESCRIPTIONS_HISTORY_P1: 'POZ_SET_PRESCRIPTIONS_HISTORY_P1',

    PRESCRIPTION_SET_DIVISION_P1_DICT: 'PRESCRIPTION_SET_DIVISION_P1_DICT',
    PRESCRIPTION_SET_MF_P1_DICT: 'PRESCRIPTION_SET_MF_P1_DICT',

    //IPOM
    POZ_SET_IPOM_P12_OBJECT: 'POZ_SET_IPOM_P12_OBJECT',
    POZ_SET_IPOM_ERROR_OBJECT: 'POZ_SET_IPOM_ERROR_OBJECT',

    //HIPOM
    POZ_SET_HIPOM_P12_OBJECT: 'POZ_SET_HIPOM_P12_OBJECT',
    POZ_SET_HIPOM_ERROR_OBJECT: 'POZ_SET_HIPOM_ERROR_OBJECT',

    //ZM
    POZ_SET_ZM_REQUEST_OBJECT: 'POZ_SET_ZM_REQUEST_OBJECT',
    POZ_SET_ZM_RESULT_OBJECT: 'POZ_SET_ZM_RESULT_OBJECT',

    POZ_ADD_ASSISTANT: 'POZ_ADD_ASSISTANT',
    POZ_REMOVE_ASSISTANT: 'POZ_REMOVE_ASSISTANT',

    POZ_TRIGGER_UP: 'POZ_TRIGGER_UP',
    POZ_TRIGGER_MED_QUESTIONS_PRINT_UP: 'POZ_TRIGGER_MED_QUESTIONS_PRINT_UP',

    POZ_ADD_TMP_REFERRAL: 'POZ_ADD_TMP_REFERRAL',
    POZ_UPDATE_TMP_REFERRAL: 'POZ_UPDATE_TMP_REFERRAL',
    POZ_TMP_REMOVE_REFERRAL: 'POZ_TMP_REMOVE_REFERRAL',
    POZ_ADD_REFERRAL: 'POZ_ADD_REFERRAL',
    POZ_ADD_CUSTOM_REFERRAL: 'POZ_ADD_CUSTOM_REFERRAL',
    POZ_UPDATE_CUSTOM_REFERRAL: 'POZ_UPDATE_CUSTOM_REFERRAL',
    POZ_REMOVE_CUSTOM_REFERRAL: 'POZ_REMOVE_CUSTOM_REFERRAL',
    POZ_ADD_CUSTOM_REFERRAL_FOR_CANCEL: 'POZ_ADD_CUSTOM_REFERRAL_FOR_CANCEL',
    POZ_ADD_REFERRAL_FOR_CANCEL: 'POZ_ADD_REFERRAL_FOR_CANCEL',

    POZ_ADD_TMP_PRESCRIPTION: 'POZ_ADD_TMP_PRESCRIPTION',
    POZ_UPDATE_TMP_PRESCRIPTION: 'POZ_UPDATE_TMP_PRESCRIPTION',
    POZ_TMP_REMOVE_PRESCRIPTION: 'POZ_TMP_REMOVE_PRESCRIPTION',
    POZ_ADD_PRESCRIPTION: 'POZ_ADD_PRESCRIPTION',
    POZ_ADD_PRESCRIPTION_FOR_CANCEL: 'POZ_ADD_PRESCRIPTION_FOR_CANCEL',

    POZ_ADD_PAPER_PRESCRIPTION: 'POZ_ADD_PAPER_PRESCRIPTION',
    POZ_ADD_PAPER_PRESCRIPTION_FOR_CANCEL: 'POZ_ADD_PAPER_PRESCRIPTION_FOR_CANCEL',

    POZ_ADD_TMP_ATTESTATION: 'POZ_ADD_TMP_ATTESTATION',
    POZ_UPDATE_TMP_ATTESTATION: 'POZ_UPDATE_TMP_ATTESTATION',
    POZ_TMP_REMOVE_ATTESTATION: 'POZ_TMP_REMOVE_ATTESTATION',
    POZ_ADD_ATTESTATION: 'POZ_ADD_ATTESTATION',
    POZ_REMOVE_ATTESTATION: 'POZ_REMOVE_ATTESTATION',
    POZ_ADD_ATTESTATION_FOR_CANCEL: 'POZ_ADD_ATTESTATION_FOR_CANCEL',

    POZ_SET_INFORMATION_TO_DOCTOR: 'POZ_SET_INFORMATION_TO_DOCTOR',
    POZ_RESET_INFORMATION_TO_DOCTOR: 'POZ_RESET_INFORMATION_TO_DOCTOR',
    POZ_SET_FINISH_INFORMATION_TO_DOCTOR: 'POZ_SET_FINISH_INFORMATION_TO_DOCTOR',
    POZ_SET_INFORMATION_TO_DOCTOR_REQEST: 'POZ_SET_INFORMATION_TO_DOCTOR_REQEST',

    // NEW NEW NEW
    POZ_ADD_TMP_ORDER: 'POZ_ADD_TMP_ORDER',
    POZ_UPDATE_TMP_ORDER: 'POZ_UPDATE_TMP_ORDER',
    POZ_TMP_REMOVE_ORDER: 'POZ_TMP_REMOVE_ORDER',
    POZ_ADD_ORDER: 'POZ_ADD_ORDER',
    POZ_REMOVE_ORDER: 'POZ_REMOVE_ORDER',
    POZ_ADD_APPOINTMENT_TO_ORDER: 'POZ_ADD_APPOINTMENT_TO_ORDER',
    POZ_ADD_ORDER_FOR_CANCEL: 'POZ_ADD_ORDER_FOR_CANCEL',

    POZ_ADD_TMP_EXEMPTION: 'POZ_ADD_TMP_EXEMPTION',
    POZ_TMP_REMOVE_EXEMPTION: 'POZ_TMP_REMOVE_EXEMPTION',
    POZ_UPDATE_TMP_EXEMPTION: 'POZ_UPDATE_TMP_EXEMPTION',

    POZ_TRIGGER_ORDERS_UP: 'POZ_TRIGGER_ORDERS_UP',
    POZ_TRIGGER_DRUG_ORDERS_UP: 'POZ_TRIGGER_ORDERS_UP',
    POZ_TRIGGER_PRESCRIPTIONS_UP: 'POZ_TRIGGER_PRESCRIPTIONS_UP',
    POZ_ADD_DRUG_PRESCRIPTION: 'POZ_ADD_DRUG_PRESCRIPTION',

    POZ_ADD_TMP_REPORT: 'POZ_ADD_TMP_REPORT',
    POZ_UPDATE_TMP_REPORT: 'POZ_UPDATE_TMP_REPORT',
    POZ_TMP_REMOVE_REPORT: 'POZ_TMP_REMOVE_REPORT',
    POZ_ADD_REPORT: 'POZ_ADD_REPORT',
    POZ_REMOVE_REPORT: 'POZ_REMOVE_REPORT',
    POZ_ADD_REPORT_FOR_CANCEL: 'POZ_ADD_REPORT_FOR_CANCEL',

    POZ_UPDATE_PATIENT_ON_VISIT: 'POZ_UPDATE_PATIENT_ON_VISIT',
    POZ_UPDATE_PATIENT_IN_WAITING_ROOM: 'POZ_UPDATE_PATIENT_IN_WAITING_ROOM',

    POZ_ADD_DOCUMENT: 'POZ_ADD_DOCUMENT',
    POZ_ADD_TICKET: 'POZ_ADD_TICKET',
    POZ_ADD_INCIDENT: 'POZ_ADD_INCIDENT',

    POZ_ADD_REFERRAL_TICKET: 'POZ_ADD_REFERRAL_TICKET',
    POZ_UPDATE_REFERRAL_TICKET: 'POZ_UPDATE_REFERRAL_TICKET',

    POZ_ADD_SUBUNIT_INFO: 'POZ_ADD_SUBUNIT_INFO',

    POZ_SET_UMX_CONTRACT_LOADING: 'POZ_SET_UMX_CONTRACT_LOADING',
    POZ_SET_UMX_CONTRACT: 'POZ_SET_UMX_CONTRACT',
    POZ_SET_ALL_UMX_CONTRACTS: 'POZ_SET_ALL_UMX_CONTRACTS',
    POZ_SET_FACILITY: 'POZ_SET_FACILITY',
    POZ_SET_TREATMENT_DECISION: 'POZ_SET_TREATMENT_DECISION',
    POZ_SET_CHOSEN_SERVICES: 'POZ_SET_CHOSEN_SERVICES',
    POZ_SET_CHOSEN_SERVICES_ICDS: 'POZ_SET_CHOSEN_SERVICES_ICDS',
    POZ_PUSH_CHOSEN_SERVICES: 'POZ_PUSH_CHOSEN_SERVICES',
    POZ_REMOVE_CHOSEN_SERVICES: 'POZ_REMOVE_CHOSEN_SERVICES',
    POZ_SET_CHOSEN_SERVICES_REPORTED_APPOINTMENT: 'POZ_SET_CHOSEN_SERVICES_REPORTED_APPOINTMENT',
    POZ_PUSH_CHOSEN_SERVICES_ICD9: 'POZ_PUSH_CHOSEN_SERVICES_ICD9',
    POZ_REMOVE_CHOSEN_SERVICES_ICD9: 'POZ_REMOVE_CHOSEN_SERVICES_ICD9',
    POZ_PUSH_SERVICE_TO_ADD: 'POZ_PUSH_SERVICE_TO_ADD',

    NFZ_APPOINTMENT_INFO_UPDATE: 'NFZ_APPOINTMENT_INFO_UPDATE',

    POZ_SET_TMP_VISIT_DATA: 'POZ_SET_TMP_VISIT_DATA',
    POZ_TMP_VISIT_DATA_TRIGGER_UP: 'POZ_TMP_VISIT_DATA_TRIGGER_UP',
    POZ_UMX_VISIT_TRIGGER_UP: 'POZ_UMX_VISIT_TRIGGER_UP',



    POZ_SET_IKZ: 'POZ_SET_IKZ',
    POZ_ADD_ICD9: 'POZ_ADD_ICD9',
    POZ_ADD_TICKET_TO_SETTLE: 'POZ_ADD_TICKET_TO_SETTLE',
    POZ_REMOVE_TICKET_FROM_SETTLE: 'POZ_REMOVE_TICKET_FROM_SETTLE',

    POZ_ADD_SUBJECT_INFO: 'POZ_ADD_SUBJECT_INFO',
    POZ_ADD_ORG_UNIT_INFO: 'POZ_ADD_ORG_UNIT_INFO',
    POZ_ADD_PROGRAM_ID: 'POZ_ADD_PROGRAM_ID',

    POZ_SET_DRUG_INFO: 'POZ_SET_DRUG_INFO',
    POZ_CLEAR_DRUG_INFO: 'POZ_CLEAR_DRUG_INFO',
    POZ_ADD_DRUG_PERMISSION: 'POZ_ADD_DRUG_PERMISSION',
    POZ_SET_MEDICAL_SERVICES: 'POZ_SET_MEDICAL_SERVICES',

    POZ_SET_OFFICE_NURSE: 'POZ_SET_OFFICE_NURSE',
    POZ_SET_OFFICE_DIETICIAN: 'POZ_SET_OFFICE_DIETICIAN',
    POZ_SET_FOCUS_APPOINTMENT_NURSE: 'POZ_SET_FOCUS_APPOINTMENT_NURSE',
    POZ_SET_OFFICE_APPOINTMENT: 'POZ_SET_OFFICE_APPOINTMENT',

    POZ_SET_MED_RESULTS_DOCUMENTATION: 'POZ_SET_MED_RESULTS_DOCUMENTATION',
    POZ_ADD_MED_RESULTS_DOCUMENTATION: 'POZ_ADD_MED_RESULTS_DOCUMENTATION',
    POZ_REMOVE_MED_RESULTS_DOCUMENTATION: 'POZ_REMOVE_MED_RESULTS_DOCUMENTATION',

    POZ_SET_MED_QUESTIONS_ICD9: 'POZ_SET_MED_QUESTIONS_ICD9',
    POZ_ADD_MED_QUESTIONS_ICD9: 'POZ_ADD_MED_QUESTIONS_ICD9',
    POZ_ADD_CONCAT_MED_QUESTIONS_ICD9: 'POZ_ADD_CONCAT_MED_QUESTIONS_ICD9',
    POZ_REMOVE_MED_QUESTIONS_ICD9: 'POZ_REMOVE_MED_QUESTIONS_ICD9',

    POZ_SET_SHOW_ENDED_FILTERS: 'POZ_SET_SHOW_ENDED_FILTERS',

    POZ_SET_PATIENT_EXAMINATIONS: 'POZ_SET_PATIENT_EXAMINATIONS',
    POZ_SET_PATIENT_EXAMINATION_VISIT: 'POZ_SET_PATIENT_EXAMINATION_VISIT',
    POZ_SET_PATIENT_EXAMINATION_SETTLEMENTS: 'POZ_SET_PATIENT_EXAMINATION_SETTLEMENTS',
    POZ_SET_PATIENT_EXAMINATIONS_SORTED: 'POZ_SET_PATIENT_EXAMINATIONS_SORTED',

    //POZ correction visit
    POZ_SET_LAST_END_APPOINTMENT: 'POZ_SET_LAST_END_APPOINTMENT',
    POZ_RESET_LAST_END_APPOINTMENT: 'POZ_RESET_LAST_END_APPOINTMENT',
    POZ_SET_IS_CORRECTION: 'POZ_SET_IS_CORRECTION',
    POZ_SET_DIAGNOSIS_FROM_EDM: 'POZ_SET_DIAGNOSIS_FROM_EDM',
    POZ_RESET_DIAGNOSIS_FROM_EDM: 'POZ_RESET_DIAGNOSIS_FROM_EDM',
    POZ_SET_CONTROL_NUMBER_ONGOING: 'POZ_SET_CONTROL_NUMBER_ONGOING',
    POZ_RESET_CONTROL_NUMBER_ONGOING: 'POZ_RESET_CONTROL_NUMBER_ONGOING',
    POZ_SET_IS_REEDITING: 'POZ_SET_IS_REEDITING',
    POZ_SET_CORRECTION_REASON: 'POZ_SET_CORRECTION_REASON',
    POZ_RESET_CORRECTION_STORE: 'POZ_RESET_CORRECTION_STORE',

    POZ_SET_OFFICE_MIDWIFE: 'POZ_SET_OFFICE_MIDWIFE',
    POZ_CLEAR_OFFICE_MIDWIFE: 'POZ_CLEAR_OFFICE_MIDWIFE',

    POZ_UPDATE_DRUG: 'POZ_UPDATE_DRUG',
    POZ_REMOVE_DRUG: 'POZ_REMOVE_DRUG',
    POZ_UPDATE_COMPOUNDED_DRUG: 'POZ_UPDATE_COMPOUNDED_DRUG',

    POZ_SET_STRATIFICATION_GROUP: 'POZ_SET_STRATIFICATION_GROUP',
    POZ_SET_STRATIFICATION_PATIENT_VERSION: 'POZ_SET_STRATIFICATION_PATIENT_VERSION',

    POZ_ADD_HISTORY_ICD: 'POZ_ADD_HISTORY_ICD',
    POZ_CLEAR_HISTORY_ICD: 'POZ_CLEAR_HISTORY_ICD',
    POZ_ADD_LEADING_ICD: 'POZ_ADD_LEADING_ICD',
    POZ_CLEAR_LEADING_ICD: 'POZ_CLEAR_LEADING_ICD',
    POZ_SET_LEADING_ICD: 'POZ_SET_LEADING_ICD',
    POZ_SET_PROPS_ICD: 'POZ_SET_PROPS_ICD',
    POZ_SET_PROPS_ICD_VER2: 'POZ_SET_PROPS_ICD_VER2',
    POZ_MOVE_ICD: 'POZ_MOVE_ICD',
    POZ_CHECK_DIAGNOSES_ORDER: 'POZ_CHECK_DIAGNOSES_ORDER',
    POZ_SET_SHOW_TRUE_ALL_ICD: 'POZ_SET_SHOW_ALL_ICD',
    POZ_SET_SHOW_ICD: 'POZ_SET_SHOW_ICD',
    POZ_SORT_DIAGNOSES_ORDER: 'POZ_SORT_DIAGNOSES_ORDER',
    POZ_SET_TMP_SAVE_STANDARD_DIAGNOSES: 'POZ_SET_TMP_SAVE_STANDARD_DIAGNOSES',
    POZ_SET_INITIAL_ACTIVE_DIAGNOSES: 'POZ_SET_INITIAL_ACTIVE_DIAGNOSES',

    POZ_ADD_VISIT_TYPE: 'POZ_ADD_VISIT_TYPE',
    POZ_SET_VISIT_TYPE: 'POZ_SET_VISIT_TYPE',
    POZ_REMOVE_VISIT_TYPE: 'POZ_REMOVE_VISIT_TYPE',
    POZ_ADD_VISIT_SUBTYPE: 'POZ_ADD_VISIT_SUBTYPE',
    POZ_SET_VISIT_SUBTYPE: 'POZ_SET_VISIT_SUBTYPE',
    POZ_REMOVE_VISIT_SUBTYPE: 'POZ_REMOVE_VISIT_SUBTYPE',
    POZ_ADD_MED_QUESTION_TEMPLATE: 'POZ_ADD_MED_QUESTION_TEMPLATE',
    POZ_SET_MED_QUESTION_TEMPLATE: 'POZ_SET_MED_QUESTION_TEMPLATE',
    POZ_TRIGGER_MED_QUESTION_TEMPLATE: 'POZ_TRIGGER_MED_QUESTION_TEMPLATE',
    POZ_ADD_DRUG_TO_DIAGNOSE_MEDICAL_HISTORY: 'POZ_ADD_DRUG_TO_DIAGNOSE_MEDICAL_HISTORY',
    POZ_ADD_DRUG_TO_DIAGNOSE_MEDICAL_HISTORY_NEW: 'POZ_ADD_DRUG_TO_DIAGNOSE_MEDICAL_HISTORY_NEW',
    POZ_ADD_DRUG_TO_DIAGNOSE: 'POZ_ADD_DRUG_TO_DIAGNOSE',
    POZ_SAVE_VISIT_TRIGGER_UP: 'POZ_SAVE_VISIT_TRIGGER_UP',

    POZ_SET_LAST_TMP_SAVE_DATE: 'POZ_SET_LAST_TMP_SAVE_DATE',
    POZ_SET_TMP_SAVE_COUNTER: 'POZ_SET_TMP_SAVE_COUNTER',
    POZ_ADD_TMP_SAVE_COUNTER: 'POZ_ADD_TMP_SAVE_COUNTER',
    POZ_SET_TMP_SAVE_LOADING: 'POZ_SET_TMP_SAVE_LOADING',
    POZ_SET_TMP_EXIT_LOADING: 'POZ_SET_TMP_EXIT_LOADING',
    POZ_EXIT_VISIT_TRIGGER_UP: 'POZ_EXIT_VISIT_TRIGGER_UP',
    POZ_SET_ALL_QUESTIONS: 'POZ_SET_ALL_QUESTIONS',

    POZ_SET_PATIENT_DOCTOR_NOTES: 'POZ_SET_PATIENT_DOCTOR_NOTES',

    POZ_SET_IPOM_DIAGNOSES: 'POZ_SET_IPOM_DIAGNOSES',
    POZ_SET_IS_IPOM_CONSULTATION: 'POZ_SET_IS_IPOM_CONSULTATION',

    POZ_SET_CONTINUOUS_DIAGNOSES_SUMMARY: 'POZ_SET_CONTINUOUS_DIAGNOSES_SUMMARY',

    POZ_SET_TRANSLATED_APPOINTMENTS_DASHBOARD: 'POZ_SET_TRANSLATED_APPOINTMENTS_DASHBOARD',
    POZ_SET_TRANSLATED_PATIENT_APPOINTMENTS_DASHBOARD: 'POZ_SET_TRANSLATED_PATIENT_APPOINTMENTS_DASHBOARD',
    POZ_SET_FOCUS_TRANSLATED_APPOINTMENT: 'POZ_SET_FOCUS_TRANSLATED_APPOINTMENT',
    POZ_SET_FOCUS_TRANSLATED_APPOINTMENT_PROPS: 'POZ_SET_FOCUS_TRANSLATED_APPOINTMENT_PROPS',

    POZ_SET_ONGOING_RIS: 'POZ_SET_ONGOING_RIS',

    POZ_SET_IMMUNIZATION: 'POZ_SET_IMMUNIZATION',
    POZ_SET_QUALIFICATION_IMMUNIZATION: 'POZ_SET_QUALIFICATION_IMMUNIZATION',

    POZ_REPLACE_PATIENT_IN_FOCUS_APPOINTMENT: 'POZ_REPLACE_PATIENT_IN_FOCUS_APPOINTMENT',

    POZ_SET_PATIENT_IDENTIFICATION_INFO: 'POZ_SET_PATIENT_IDENTIFICATION_INFO',

    POZ_SET_LAST_INSURANCE_IDENTIFICATION_INFO: 'POZ_SET_LAST_INSURANCE_IDENTIFICATION_INFO',
    POZ_SET_INSURANCE_IDENTIFICATION_INFO_LOADING: 'POZ_SET_INSURANCE_IDENTIFICATION_INFO_LOADING',

    POZ_SET_APPOINTMENT_IKZ: 'POZ_SET_APPOINTMENT_IKZ',
    POZ_ADD_APPOINTMENT_IKZ: 'POZ_ADD_APPOINTMENT_IKZ',
    POZ_DELETE_APPOINTMENT_IKZ: 'POZ_DELETE_APPOINTMENT_IKZ',
    POZ_SET_APPOINTMENT_IKZ_INFO: 'POZ_SET_APPOINTMENT_IKZ_INFO',
    POZ_SET_APPOINTMENT_IKZ_AMOUNT: 'POZ_SET_APPOINTMENT_IKZ_AMOUNT',

    // SCHEDULE MODULE
    SCHEDULE_SET_PATIENT_VISITS: 'SCHEDULE_SET_PATIENT_VISITS',
    SCHEDULE_ADD_PATIENT_VISIT: 'SCHEDULE_ADD_PATIENT_VISIT',
    SCHEDULE_SET_SLOTS: 'SCHEDULE_SET_SLOTS',
    SCHEDULE_SET_ONECLICK_ADD_MODAL: 'SCHEDULE_SET_ONECLICK_ADD_MODAL',
    SCHEDULE_SET_CURRENT_BLOCK: 'SCHEDULE_SET_CURRENT_BLOCK',

    // STATS MODULE
    STATS_UPDATE_FEEDBACK: 'STATS_UPDATE_FEEDBACK',

    // SCHEDULE MODULE
    CLINICS_SET_ALL: 'CLINICS_SET_ALL',
    CLINICS_SET_ALL_TMP: 'CLINICS_SET_ALL_TMP',
    CLINICS_SET_ACTIVE: 'CLINICS_SET_ACTIVE',

    // REGISTRATION
    REGISTRATION_RESET: 'REGISTRATION_RESET',
    REGISTRATION_SET_PATIENT: 'REGISTRATION_SET_PATIENT',
    REGISTRATION_SET_WORKER: 'REGISTRATION_SET_WORKER',
    REGISTRATION_SET_EWUS_ON: 'REGISTRATION_SET_EWUS_ON',
    REGISTRATION_SET_EWUS_OFF: 'REGISTRATION_SET_EWUS_OFF',
    REGISTRATION_ADD_SALE: 'REGISTRATION_ADD_SALE',
    REGISTRATION_ADD_SCHEDULE: 'REGISTRATION_ADD_SCHEDULE',
    REGISTRATION_EDIT_SCHEDULE: 'REGISTRATION_EDIT_SCHEDULE',
    REGISTRATION_REMOVE_SCHEDULE: 'REGISTRATION_REMOVE_SCHEDULE',
    REGISTRATION_REMOVE_SCHEDULE_2: 'REGISTRATION_REMOVE_SCHEDULE_2',
    REGISTRATION_SET_SCHEDULE: 'REGISTRATION_SET_SCHEDULE',
    REGISTRATION_CLEAR_EREFERRALS: 'REGISTRATION_CLEAR_EREFERRALS',
    REGISTRATION_ADD_IFORMATION: 'REGISTRATION_ADD_IFORMATION',
    REGISTRATION_REMOVE_IFORMATION: 'REGISTRATION_REMOVE_IFORMATION.L',
    REGISTRATION_REMOVE_ENTITLEMENT: 'REGISTRATION_REMOVE_ENTITLEMENT',
    REGISTRATION_REMOVE_SALE: 'REGISTRATION_REMOVE_SALE',
    REGISTRATION_SET_EWUS: 'REGISTRATION_SET_EWUS',
    REGISTRATION_NULL_EWUS: 'REGISTRATION_NULL_EWUS',
    REGISTRATION_SET_EWUS_NEW: 'REGISTRATION_SET_EWUS_NEW',
    REGISTRATION_NULL_EWUS_NEW: 'REGISTRATION_NULL_EWUS_NEW',
    REGISTRATION_AUTO_NAVIGATE: 'REGISTRATION_AUTO_NAVIGATE',

    // NEW NEW NEW FOR REGISTRATION
    REGISTRATION_SET_STATUSES: 'REGISTRATION_SET_STATUSES',
    REGISTRATION_ADD_DECLARATION: 'REGISTRATION_ADD_DECLARATION',
    REGISTRATION_ADD_RELATIVE: 'REGISTRATION_ADD_RELATIVE',
    REGISTRATION_REMOVE_RELATIVES: 'REGISTRATION_REMOVE_RELATIVES',
    REGISTRATION_ADD_ENTITLEMENT: 'REGISTRATION_ADD_ENTITLEMENT',

    REGISTRATION_SET_PATIENT_PRIVILEGES: 'REGISTRATION_SET_PATIENT_PRIVILEGES',

    REGISTRATION_ADD_INCIDENT: 'REGISTRATION_ADD_INCIDENT',
    REGISTRATION_ADD_NOTE: 'REGISTRATION_ADD_NOTE',
    REGISTRATION_ADD_ISSUED_RESULT: 'REGISTRATION_ADD_ISSUED_RESULT',
    REGISTRATION_ADD_NOTIFICATION: 'REGISTRATION_ADD_NOTIFICATION',
    REGISTRATION_ADD_HEALTH_RECORD_PROPOSAL: 'REGISTRATION_ADD_HEALTH_RECORD_PROPOSAL',
    REGISTRATION_ADD_TICKET: 'REGISTRATION_ADD_TICKET',
    REGISTRATION_ADD_ACTIVE_BUNDLE: 'REGISTRATION_ADD_ACTIVE_BUNDLE',
    REGISTRATION_SET_UNFINISHED_VISIT: 'REGISTRATION_SET_UNFINISHED_VISIT',
    REGISTRATION_EREFERRAL_READING: 'REGISTRATION_EREFERRAL_READING',
    REGISTRATION_ADD_EREFERRAL: 'REGISTRATION_ADD_EREFERRAL',
    REGISTRATION_SET_IKZ: 'REGISTRATION_SET_IKZ',

    REGISTRATION_SET_TICKET_STATUS: 'REGISTRATION_SET_TICKET_STATUS',

    REGISTRATION_SET_PATIENT_TELEPHONE_VERIFIED: 'REGISTRATION_SET_PATIENT_TELEPHONE_VERIFIED',

    REGISTRATION_SET_TRANSLATED_FILES: 'REGISTRATION_SET_TRANSLATED_FILES',

    REGISTRATION_SET_SALES: 'REGISTRATION_SET_SALES',

    REGISTRATION_SET_REGISTRATION_FACILITY: 'REGISTRATION_SET_REGISTRATION_FACILITY',

    // WORKER MODULE
    WORKER_PUSH_ALL: 'WORKER_PUSH_ALL',
    WORKER_PUSH: 'WORKER_PUSH',
    WORKER_PUT: 'WORKER_PUT',
    WORKER_DELETE: 'WORKER_DELETE',
    WORKER_SET_MY_DATA: "WORKER_SET_MY_DATA",
    WORKERS_CLEAR_MODULE: 'WORKERS_CLEAR_MODULE',
    WORKER_PRETEND: 'WORKER_PRETEND',
    WORKER_PUSH_ALL_DOCTORS: 'WORKER_PUSH_ALL_DOCTORS',

    WORKER_SET_MEDICAL_TEAMS: 'WORKER_SET_MEDICAL_TEAMS',
    WORKER_DELETE_MEDICAL_TEAM: 'WORKER_DELETE_MEDICAL_TEAM',
    WORKER_SET_AVAILABLE_PAPER_GROUP: 'WORKER_SET_AVAILABLE_PAPER_GROUP',

    EMPLOYEE_CLEAR: 'EMPLOYEE_CLEAR',
    EMPLOYEE_SET_ALL: 'EMPLOYEE_SET_ALL',
    EMPLOYEE_PUSH: 'EMPLOYEE_PUSH',
    EMPLOYEE_PUT: 'EMPLOYEE_PUT',
    EMPLOYEE_DELETE: 'EMPLOYEE_DELETE',
    EMPLOYEE_SET_ME: 'EMPLOYEE_SET_ME',
    EMPLOYEE_SET_SCHEDULED: 'EMPLOYEE_SET_SCHEDULED',
    EMPLOYEE_SET_FOCUS: 'EMPLOYEE_SET_FOCUS',
    EMPLOYEE_RESET: 'EMPLOYEE_RESET',

    EMPLOYEE_TRIGGER_UPDATE: 'EMPLOYEE_TRIGGER_UPDATE',

    EMPLOYEE_POSITION_TYPE_SET_ALL: 'EMPLOYEE_POSITION_TYPE_SET_ALL',
    EMPLOYEE_POSITION_TYPE_ADD: 'EMPLOYEE_POSITION_TYPE_ADD',
    EMPLOYEE_POSITION_TYPE_REMOVE: 'EMPLOYEE_POSITION_TYPE_REMOVE',
    EMPLOYEE_POSITION_TYPE_EDIT: 'EMPLOYEE_POSITION_TYPE_EDIT',

    EMPLOYEE_POSITION_SUBTYPE_SET_ALL: 'EMPLOYEE_POSITION_SUBTYPE_SET_ALL',
    EMPLOYEE_POSITION_SUBTYPE_ADD: 'EMPLOYEE_POSITION_SUBTYPE_ADD',
    EMPLOYEE_POSITION_SUBTYPE_REMOVE: 'EMPLOYEE_POSITION_SUBTYPE_REMOVE',
    EMPLOYEE_POSITION_SUBTYPE_EDIT: 'EMPLOYEE_POSITION_SUBTYPE_EDIT',

    EMPLOYEE_POSITION_SET: 'EMPLOYEE_POSITION_SET',
    EMPLOYEE_MY_POSITION_SET: 'EMPLOYEE_MY_POSITION_SET',

    EMPLOYEE_PERMISSION_TYPE_SET_ALL: 'EMPLOYEE_PERMISSION_TYPE_SET_ALL',
    EMPLOYEE_PERMISSION_TYPE_ADD: 'EMPLOYEE_PERMISSION_TYPE_ADD',
    EMPLOYEE_PERMISSION_TYPE_REMOVE: 'EMPLOYEE_PERMISSION_TYPE_REMOVE',
    EMPLOYEE_PERMISSION_TYPE_EDIT: 'EMPLOYEE_PERMISSION_TYPE_EDIT',

    EMPLOYEE_LIST_FILTERS_SET: 'EMPLOYEE_LIST_FILTERS_SET',
    EMPLOYEE_LIST_FILTERS_CLEAR: 'EMPLOYEE_LIST_FILTERS_CLEAR',

    EMPLOYEE_SET_IKZ: 'EMPLOYEE_SET_IKZ',

    // IDENTITY MODULE
    GENERAL_COOKIES_ACCEPT: 'GENERAL_COOKIES_ACCEPT',
    IDENTITY_OPEN_SESSION: 'IDENTITY_OPEN_SESSION',
    IDENTITY_CLOSE_SESSION: 'IDENTITY_CLOSE_SESSION',
    IDENTITY_SESSION_UPDATE_TIME: 'IDENTITY_SESSION_UPDATE_TIME',
    IDENTITY_SESSION_RESET_TIMER: 'IDENTITY_SESSION_RESET_TIMER',
    IDENTITY_SET_ROLES: 'IDENTITY_SET_ROLES',
    IDENTITY_SET_ALL_USERS: 'IDENTITY_SET_ALL_USERS',
    IDENTITY_RENEW_USER: 'IDENTITY_RENEW_USER',
    IDENTITY_PUSH_NEW_USER: 'IDENTITY_PUSH_NEW_USER',
    IDENTITY_DELETE_USER: 'IDENTITY_DELETE_USER',
    IDENTITY_ENABLE_USER: 'IDENTITY_ENABLE_USER',
    IDENTITY_DISABLE_USER: 'IDENTITY_DISABLE_USER',
    IDENTITY_EDIT_USER: 'IDENTITY_EDIT_USER',
    IDENTITY_TIMER_UPDATE: "IDENTITY_TIMER_UPDATE",
    IDENTITY_SET_TIMER: 'IDENTITY_SET_TIMER',

    // TIME MODULE
    TIME_STOP: 'TIME_STOP',
    TIME_UPDATE_CURRENT: 'TIME_UPDATE_CURRENT',

    // TEST MODULE
    TEST_SET_PATIENT: 'TEST_SET_PATIENT',
    TEST_SET_EWUS: 'TEST_SET_EWUS',
    TEST_ADD_ICD: 'TEST_ADD_ICD',
    TEST_REMOVE_ICD: 'TEST_REMOVE_ICD',
    TEST_ADD_DRUG: 'TEST_ADD_DRUG',
    TEST_REMOVE_DRUG: 'TEST_REMOVE_DRUG',
    TEST_TRIGGER_UP: 'TEST_TRIGGER_UP',

    TEST_RESET: 'TEST_RESET',

    TEST_UPDATE_REFERRALS: 'TEST_UPDATE_REFERRALS',
    TEST_EMP_REFFERALS: 'TEST_EMP_REFFERALS',
    TEST_EMPLOYEE_REFFERALS: 'TEST_EMPLOYEE_REFFERALS',
    TEST_UPDATE_REFERRAL: 'TEST_UPDATE_REFERRAL',
    TEST_ADD_REFERRAL: 'TEST_ADD_REFERRAL',
    TEST_REMOVE_REFERRAL: 'TEST_REMOVE_REFERRAL',

    TEST_UPDATE_PRESCRIPTIONS: 'TEST_UPDATE_PRESCRIPTIONS',
    TEST_EMP_PRESCRIPTIONS: 'TEST_EMP_PRESCRIPTIONS',
    TEST_EMPLOYEE_PRESCRIPTIONS: 'TEST_EMPLOYEE_PRESCRIPTIONS',
    TEST_UPDATE_PRESCRIPTION: 'TEST_UPDATE_PRESCRIPTION',
    TEST_ADD_PRESCRIPTION: 'TEST_ADD_PRESCRIPTION',
    TEST_REMOVE_PRESCRIPTION: 'TEST_REMOVE_PRESCRIPTION',


    TEST_ADD_EXEMPTION: 'TEST_ADD_EXEMPTION',
    TEST_REMOVE_EXEMPTION: 'TEST_REMOVE_EXEMPTION',
    TEST_REMOVE_ALL_DRUGS: 'TEST_REMOVE_ALL_DRUGS',

    // REHABILITATION MODULE
    REHABILITATION_RESET: 'REHABILITATION_RESET',
    REHABILITATION_ADD_EMPLOYEE: 'REHABILITATION_ADD_EMPLOYEE',
    REHABILITATION_REMOVE_EMPLOYEE: 'REHABILITATION_REMOVE_EMPLOYEE',
    REHABILITATION_SET_SELECTED: 'REHABILITATION_SET_SELECTED',

    //RIS MODULE
    RIS_RESET: 'RIS_RESET',
    RIS_DATA_FLUSH: 'RIS_DATA_FLUSH',
    RIS_ADD_EMPLOYEE: 'RIS_ADD_EMPLOYEE',
    RIS_REMOVE_EMPLOYEE: 'RIS_REMOVE_EMPLOYEE',
    RIS_SET_SELECTED: 'RIS_SET_SELECTED',
    RIS_SET_UNSELECTED: 'RIS_SET_UNSELECTED',
    RIS_UPDATE_STATUS: 'RIS_UPDATE_STATUS',
    RIS_SET_ALL: 'RIS_SET_ALL',
    RIS_SET_PATIENTS: 'RIS_SET_PATIENTS',
    RIS_APPOINTMENT_PUSH: 'RIS_APPOINTMENT_PUSH',
    RIS_APPOINTMENT_TEMP_ADD: 'RIS_APPOINTMENT_TEMP_ADD',
    RIS_SET_FOCUS_APPOINTMENT: 'RIS_SET_FOCUS_APPOINTMENT',
    RIS_SET_ONGOING_APPOINTMENT: 'RIS_SET_ONGOING_APPOINTMENT',
    RIS_SET_APPOINTMENT_INITIAL_DATA: 'RIS_SET_APPOINTMENT_INITIAL_DATA',
    RIS_SET_OBJECT_RIS: 'RIS_SET_OBJECT_RIS',
    RIS_SET_OBJECT_RIS_AFTER_FILES_ADD: 'RIS_SET_OBJECT_RIS_AFTER_FILES_ADD',
    RIS_SET_FOCUS_APPOINTMENT_AFTER_FILES_ADD: 'RIS_SET_FOCUS_APPOINTMENT_AFTER_FILES_ADD',
    RIS_SET_TEMP_WORKLIST: 'RIS_SET_TEMP_WORKLIST',
    RIS_SET_DEVICES: 'RIS_SET_DEVICES',
    RIS_TRIGGER_UP: 'RIS_TRIGGER_UP',
    RIS_TRIGGER_ADD_UP: 'RIS_TRIGGER_ADD_UP',
    RIS_SET_RISAPPOINTMENTS: 'RIS_SET_RISAPPOINTMENTS',
    RIS_RESET_RISAPPOINTMENTS: 'RIS_RESET_RISAPPOINTMENTS',
    RIS_PUSH_RISAPPOINTMENT: 'RIS_PUSH_RISAPPOINTMENT',
    RIS_SET_IS_EDITING_APP: 'RIS_SET_IS_EDITING_APP',
    RIS_SET_SELECTED_DATE: 'RIS_SET_SELECTED_DATE',
    RIS_PUSH_TICKET_TO_STORE: 'RIS_PUSH_TICKET_TO_STORE',
    RIS_RESET_TICKET_TO_STORE: 'RIS_RESET_TICKET_TO_STORE',
    RIS_SET_SUPERVISOR_TK: 'RIS_SET_SUPERVISOR_TK',
    RIS_RESET_SUPERVISOR_TK: 'RIS_RESET_SUPERVISOR_TK',

    SWX_SET_FOCUS: 'SWX_SET_FOCUS',

    RIS_ADD_ASSISTANT: 'RIS_ADD_ASSISTANT',
    RIS_REMOVE_ASSISTANT: 'RIS_REMOVE_ASSISTANT',

    RIS_SET_UNIT: 'RIS_SET_UNIT',
    RIS_RESET_UNIT: 'RIS_RESET_UNIT',
    RIS_SET_OFFICE: 'RIS_SET_OFFICE',

    RIS_SET_IKZ: 'RIS_SET_IKZ',

    // RESET VUEX STATE -> FULL RESET
    GENERAL_RESET: 'GENERAL_RESET',

    // CONFIG MODULE
    CONFIG_SETUP_DICTIONARIES: 'CONFIG_SETUP_DICTIONARIES',

    CONFIG_SETUP_SCHEDULE_CONFIG: 'CONFIG_SETUP_SCHEDULE_CONFIG',
    CONFIG_SET_LINK: 'CONFIG_SET_LINK',
    CONFIG_SET_LINK_STATE: 'CONFIG_SET_LINK_STATE',

    CONFIG_SET_PRINT_LOADING: 'CONFIG_SET_PRINT_LOADING',

    //CONTRACTORS MODULE
    CONTRACTORS_SET_ALL: 'CONTRACTORS_SET_ALL',

    //FINANCE MODULE
    MEDSETS_SET_ITEMS_FOR_QUEUE: 'MEDSETS_SET_ITEMS_FOR_QUEUE',
    MEDSETS_SET_CLEAR_ITEMS_FOR_QUEUE: 'MEDSETS_SET_CLEAR_ITEMS_FOR_QUEUE',
    MEDSETS_REMOVE_FROM_QUEUE: 'MEDSETS_REMOVE_FROM_QUEUE',
    MEDSETS_SET_QUEUE_LOAD: 'MEDSETS_SET_QUEUE_LOAD',

    FINANCE_SUBJECTS_SET_ALL: 'FINANCE_SUBJECTS_SET_ALL',
    FINANCE_SUBJECTS_PUSH: 'FINANCE_SUBJECTS_PUSH',
    FINANCE_SUBJECTS_SET_UPDATE: 'FINANCE_SUBJECTS_SET_UPDATE',
    FINANCE_SUBJECTS_SET_WITH_MEDSPEC_TYPE: 'FINANCE_SUBJECTS_SET_WITH_MEDSPEC_TYPE',

    FINANCE_SUBJECTS_SET_ALL_IN_MY_DOMAIN: 'FINANCE_SUBJECTS_SET_ALL_IN_MY_DOMAIN',
    FINANCE_SUBJECTS_IN_MY_DOMAIN_SET_UPDATE: 'FINANCE_SUBJECTS_IN_MY_DOMAIN_SET_UPDATE',


    FINANCE_INVOICES_SET_ALL: 'FINANCE_INVOICES_SET_ALL',
    FINANCE_INVOICES_PUSH: 'FINANCE_INVOICES_PUSH',

    FINANCE_DATABASES_SET_ALL: 'FINANCE_DATABASES_SET_ALL',
    FINANCE_DATABASES_PUSH: 'FINANCE_DATABASES_PUSH',
    FINANCE_DATABASES_SPLICE: 'FINANCE_DATABASES_SPLICE',

    FINANCE_IKZ_SET_ALL: 'FINANCE_IKZ_SET_ALL',
    FINANCE_IKZ_SET_SELECTEDWORKER_ID: 'FINANCE_IKZ_SET_SELECTEDWORKER_ID',
    FINANCE_IKZ_SET_SELECTEDWORKER: 'FINANCE_IKZ_SET_SELECTEDWORKER',

    FINANCE_CONTRACTS_SET_ALL: 'FINANCE_CONTRACTS_SET_ALL',
    FINANCE_CONTRACTS_PUSH: 'FINANCE_CONTRACTS_PUSH',
    FINANCE_CONTRACTS_SET_UPDATE: 'FINANCE_CONTRACTS_SET_UPDATE',

    FINANCE_UPLOAD_DOWNLOAD_PUSH: 'FINANCE_UPLOAD_DOWNLOAD_PUSH',

    FINANCE_SET_CLUSTERS: 'FINANCE_SET_CLUSTERS',
    FINANCE_SET_FACILTIES: 'FINANCE_SET_FACILTIES',
    CONFIG_SET_CURRENT_FACILITY: 'CONFIG_SET_CURRENT_FACILITY',

    SET_MY_SETTINGS: 'SET_MY_SETTINGS',
    UPDATE_MY_SETTINGS: 'UPDATE_MY_SETTINGS',

    FINANCE_UMXID_SET: 'FINANCE_UMXID_SET',
    FINANCE_UMXID_RESET: 'FINANCE_UMXID_RESET',

    FINANCE_UMX_SET: 'FINANCE_UMX_SET',
    FINANCE_UMX_RESET: 'FINANCE_UMX_RESET',

    FINANCE_UMXRANGE_SET: 'FINANCE_UMXRANGE_SET',
    FINANCE_UMXRANGE_RESET: 'FINANCE_UMXRANGE_RESET',

    FINANCE_UMXMONTH_SET: 'FINANCE_UMXMONTH_SET',
    FINANCE_UMXMONTH_RESET: 'FINANCE_UMXMONTH_RESET',

    FINANCE_UMXROUTE_SET: 'FINANCE_UMXROUTE_SET',

    //WORKER GROUP
    FINANCE_WORKER_GROUP_PUSH: 'FINANCE_WORKER_GROUP_PUSH',
    FINANCE_WORKER_GROUP_SELECTED: 'FINANCE_WORKER_GROUP_SELECTED',


    //CRM MODULE
    CRM_TRIGGER_UP: 'CRM_TRIGGER_UP',
    CRM_TRIGGER_CRM_UP: 'CRM_TRIGGER_CRM_UP',
    CRM_SET_REPORTS: 'CRM_SET_REPORTS',
    CRM_SET_MYREPORTS: 'CRM_SET_MYREPORTS',
    CRM_SET_LAST_ALLOCATED: 'CRM_SET_LAST_ALLOCATED',
    CRM_SET_INDEX_ALLOCATED: 'CRM_SET_INDEX_ALLOCATED',
    CRM_SET_INDEX_COMPLETED: 'CRM_SET_INDEX_COMPLETED',
    CRM_SET_INDEX_WAITING: 'CRM_SET_INDEX_WAITING',
    CRM_SET_TABINDEX: 'CRM_SET_TABINDEX',

    //OrganizationalUnit module
    FINANCE_ORGANIZATIONALUNIT_SET_ALL: 'FINANCE_ORGANIZATIONALUNIT_SET_ALL',
    FINANCE_ORGANIZATIONALUNIT_TYPE_SET_ALL: 'FINANCE_ORGANIZATIONALUNIT_TYPE_SET_ALL',

    FINANCE_ORGANIZATIONALSUBUNIT_SET_ALL: 'FINANCE_ORGANIZATIONALSUBUNIT_SET_ALL',
    FINANCE_ORGANIZATIONALSUBUNIT_UPDATE: 'FINANCE_ORGANIZATIONALSUBUNIT_UPDATE',
    SUBUNIT_SET_OFFICES: 'SUBUNIT_SET_OFFICES',

    FINANCE_COMPANY_SET_ALL: 'FINANCE_COMPANY_SET_ALL',

    // P12
    P12_CODE_ADD: 'P12_CODE_ADD',
    P12_CODE_REQUEST: 'P12_CODE_REQUEST',

    //Queue worker creds
    ERC_WORKERCREDS_SET_WORKERCONTENT: 'ERC_WORKERCREDS_SET_WORKERCONTENT',

    // UAC
    UAC_NULLIFY_CONNECTION: 'UAC_NULLIFY_CONNECTION',
    UAC_SET_CONNECTION: 'UAC_SET_CONNECTION',
    UAC_SET_CONNECTING_INDICATOR: 'UAC_SET_CONNECTING_INDICATOR',
    UAC_SET_CURRENT: 'UAC_SET_CURRENT',
    UAC_SET_LAST_UPDATE: 'UAC_SET_LAST_UPDATE',

    // TALK
    TALK_NULLIFY_CONNECTION: 'TALK_NULLIFY_CONNECTION',
    TALK_SET_CONNECTION: 'TALK_SET_CONNECTION',
    TALK_SET_CONNECTING_INDICATOR: 'TALK_SET_CONNECTING_INDICATOR',
    TALK_SET_GROUPS: "TALK_SET_GROUPS",
    TALK_ADD_MESSAGE: "TALK_ADD_MESSAGE",

    // EWUS
    EWUS_FLUSH_DATA: 'EWUS_FLUSH_DATA',
    EWUS_TRIGGER_UP: 'EWUS_TRIGGER_UP',
    EWUS_SET_EWUS: 'EWUS_SET_EWUS',
    EWUS_SET_EWUS_PENDING: 'EWUS_SET_EWUS_PENDING',
    EWUS_RESET: 'EWUS_RESET',
    EWUS_SET_EWUS_GUID: 'EWUS_SET_EWUS_GUID',
    EWUS_ADD_PATIENT: 'EWUS_ADD_PATIENT',
    EWUS_EDIT_PATIENT: 'EWUS_EDIT_PATIENT',
    EWUS_RESET_NUMBER_OF_REQUESTS: 'EWUS_RESET_NUMBER_OF_REQUESTS',
    EWUS_APPS_DASHBOARD_TRIGGER_UP: 'EWUS_APPS_DASHBOARD_TRIGGER_UP',
    EWUS_EDIT_PATIENT_BY_PESEL: 'EWUS_EDIT_PATIENT_BY_PESEL',

    EWUS_SET_EWUS_RESERVATION: 'EWUS_SET_EWUS_RESERVATION',
    EWUS_SET_EWUS_PENDING_RESERVATION: 'EWUS_SET_EWUS_PENDING_RESERVATION',
    EWUS_SET_EWUS_GUID_RESERVATION: 'EWUS_SET_EWUS_GUID_RESERVATION',
    EWUS_RESET_RESERVATION: 'EWUS_RESET_RESERVATION',
    EWUS_TRIGGER_UP_RESERVATION: 'EWUS_TRIGGER_UP_RESERVATION',

    // CYTO
    CYTOLOGY_TRIGGER_UP: 'CYTOLOGY_TRIGGER_UP',
    CYTOLOGY_TRIGGER_2_UP: 'CYTOLOGY_TRIGGER_2_UP',
    CYTOLOGY_SET_ACTIVE_STAND: 'CYTOLOGY_SET_ACTIVE_STAND',
    CYTOLOGY_RESET_ACTIVE_STAND: 'CYTOLOGY_RESET_ACTIVE_STAND',
    CYTOLOGY_ADD_ACTIVE_STAND: 'CYTOLOGY_ADD_ACTIVE_STAND',

    CYTOLOGY_SET_WOKPLACE_FACILITY: 'CYTOLOGY_SET_WOKPLACE_FACILITY',
    CYTOLOGY_SET_WOKPLACE_ROOM: 'CYTOLOGY_SET_WOKPLACE_ROOM',
    CYTOLOGY_SET_CURRENT_SUBUNIT: 'CYTOLOGY_SET_CURRENT_SUBUNIT',
    CYTOLOGY_SET_CURRENT_UNIT: 'CYTOLOGY_SET_CURRENT_UNIT',
    CYTOLOGY_SET_WOKPLACE_OFFICE: 'CYTOLOGY_SET_WOKPLACE_OFFICE',
    CYTOLOGY_SET_LAB_EXAMS: 'CYTOLOGY_SET_LAB_EXAMS',
    CYTOLOGY_SET_LAB_EXAM_BASES: 'CYTOLOGY_SET_LAB_EXAM_BASES',
    CYTOLOGY_SET_ORDER: 'CYTOLOGY_SET_ORDER',
    CYTOLOGY_RESET_ORDERS_LIST: 'CYTOLOGY_RESET_ORDERS_LIST',
    CYTOLOGY_REMOVE_ORDER: 'CYTOLOGY_REMOVE_ORDER',
    CYTOLOGY_SET_TMP_ORDERS: 'CYTOLOGY_SET_TMP_ORDERS',
    CYTOLOGY_SET_COLLECTED_DATE: 'CYTOLOGY_SET_COLLECTED_DATE',
    CYTOLOGY_SET_COLLECTED_BY: 'CYTOLOGY_SET_COLLECTED_BY',
    CYTOLOGY_SET_REFERRING_PHYSICIAN: 'CYTOLOGY_SET_REFERRING_PHYSICIAN',
    CYTOLOGY_UPDATE_ORDERS: 'CYTOLOGY_UPDATE_ORDERS',

    // ZUS MODULE
    ZUS_PASSWORD_CODE_ADD: 'ZUS_PASSWORD_CODE_ADD',
    ZUS_PASSWORD_REQUEST: 'ZUS_PASSWORD_REQUEST',
    ZUS_GUID_ADD: 'ZUS_GUID_ADD',
    ZUS_ADD_TMP_EXEMPTION: 'US_ADD_TMP_EXEMPTION',
    ZUS_UPDATE_TMP_EXEMPTION: 'ZUS_UPDATE_TMP_EXEMPTION',
    ZUS_REMOVE_TMP_EXEMPTION: 'ZUS_REMOVE_TMP_EXEMPTION',
    ZUS_UPDATE_REQUEST: 'ZUS_UPDATE_REQUEST',
    ZUS_ADD_EXEMPTION: 'ZUS_ADD_EXEMPTION',
    ZUS_ADD_EXEMPTION_FOR_CANCEL: 'ZUS_ADD_EXEMPTION_FOR_CANCEL',
    ZUS_TRIGGER_UP: 'ZUS_TRIGGER_UP',
    ZUS_DATA_UPDATE: 'ZUS_DATA_UPDATE',
    ZUS_REMOVE_REQUEST: 'ZUS_REMOVE_REQUEST',
    ZUS_RESET: 'ZUS_RESET',
    ZUS_RESET_2: 'ZUS_RESET_2',
    ZUS_RESET_3: 'ZUS_RESET_3',

    // RESULTS MODULE
    RESULTS_LAB_EXAMINATION_TYPE_SET_ALL: 'RESULTS_LAB_EXAMINATION_TYPE_SET_ALL',
    RESULTS_LAB_EXAMINATION_TYPE_ADD: 'RESULTS_LAB_EXAMINATION_TYPE_ADD',
    RESULTS_LAB_EXAMINATION_TYPE_REMOVE: 'RESULTS_LAB_EXAMINATION_TYPE_REMOVE',
    RESULTS_LAB_EXAMINATION_TYPE_EDIT: 'RESULTS_LAB_EXAMINATION_TYPE_EDIT',

    RESULTS_IMAGE_EXAMINATION_TYPE_SET_ALL: 'RESULTS_IMAGE_EXAMINATION_TYPE_SET_ALL',

    RESULTS_SAMPLE_BASE_SET_ALL: 'RESULTS_SAMPLE_BASE_SET_ALL',
    RESULTS_SAMPLE_BASE_ADD: 'RESULTS_SAMPLE_BASE_ADD',
    RESULTS_SAMPLE_BASE_REMOVE: 'RESULTS_SAMPLE_BASE_REMOVE',
    RESULTS_SAMPLE_BASE_EDIT: 'RESULTS_SAMPLE_BASE_EDIT',

    // RESULTS MODULE - NEW
    RESULTS_OTHER_EXAMINATION_TYPE_SET_ALL: 'RESULTS_OTHER_EXAMINATION_TYPE_SET_ALL',
    RESULTS_OTHER_EXAMINATION_TYPE_ADD: 'RESULTS_OTHER_EXAMINATION_TYPE_ADD',
    RESULTS_OTHER_EXAMINATION_TYPE_REMOVE: 'RESULTS_OTHER_EXAMINATION_TYPE_REMOVE',
    RESULTS_OTHER_EXAMINATION_TYPE_EDIT: 'RESULTS_OTHER_EXAMINATION_TYPE_EDIT',

    RESULTS_DENTAL_EXAMINATION_TYPE_SET_ALL: 'RESULTS_DENTAL_EXAMINATION_TYPE_SET_ALL',
    RESULTS_DENTAL_EXAMINATION_TYPE_ADD: 'RESULTS_DENTAL_EXAMINATION_TYPE_ADD',
    RESULTS_DENTAL_EXAMINATION_TYPE_REMOVE: 'RESULTS_DENTAL_EXAMINATION_TYPE_REMOVE',
    RESULTS_DENTAL_EXAMINATION_TYPE_EDIT: 'RESULTS_DENTAL_EXAMINATION_TYPE_EDIT',

    RESULTS_PSYCHE_EXAMINATION_TYPE_SET_ALL: 'RESULTS_PSYCHE_EXAMINATION_TYPE_SET_ALL',
    RESULTS_PSYCHE_EXAMINATION_TYPE_ADD: 'RESULTS_PSYCHE_EXAMINATION_TYPE_ADD',
    RESULTS_PSYCHE_EXAMINATION_TYPE_REMOVE: 'RESULTS_PSYCHE_EXAMINATION_TYPE_REMOVE',
    RESULTS_PSYCHE_EXAMINATION_TYPE_EDIT: 'RESULTS_PSYCHE_EXAMINATION_TYPE_EDIT',

    RESULTS_PHYSIO_EXAMINATION_TYPE_SET_ALL: 'RESULTS_PHYSIO_EXAMINATION_TYPE_SET_ALL',
    RESULTS_PHYSIO_EXAMINATION_TYPE_ADD: 'RESULTS_PHYSIO_EXAMINATION_TYPE_ADD',
    RESULTS_PHYSIO_EXAMINATION_TYPE_REMOVE: 'RESULTS_PHYSIO_EXAMINATION_TYPE_REMOVE',
    RESULTS_PHYSIO_EXAMINATION_TYPE_EDIT: 'RESULTS_PHYSIO_EXAMINATION_TYPE_EDIT',

    //DEVICES MODULE
    MEDICAL_DEVICE_SET_ALL: 'MEDICAL_DEVICE_SET_ALL',

    //SLOT MODULE
    SLOT_SET_ALL: 'SLOT_SET_ALL',
    SLOT_SET_EMPLOYEE: 'SLOT_SET_EMPLOYEE',
    SLOT_REFRESH: 'SLOT_REFRESH',

    //DIGITMED MODULE
    DIGITMED_MODULES_SET_ALL: 'DIGITMED_MODULES_SET_ALL',

    // SOLAB
    SOLAB_SEND_MATERIAL: 'SOLAB_SEND_MATERIAL',
    SOLAB_TRIGGER_UP: 'SOLAB_TRIGGER_UP',
    SOLAB_ADD_SENDED_ORDER: 'SOLAB_ADD_SENDED_ORDER',

    //EZWM
    EZWM_ORDER_ADD: 'EZWM_ORDER_ADD',
    EZWM_ORDER_REMOVE: 'EZWM_ORDER_REMOVE',
    EZWM_ORDER_UPDATE: 'EZWM_ORDER_UPDATE',
    EZWM_CANCEL_ORDER_ADD: 'EZWM_CANCEL_ORDER_ADD',
    EZWM_CANCEL_ORDER_REMOVE: 'EZWM_CANCEL_ORDER_REMOVE',
    EZWM_RESET: 'EZWM_RESET',
    EZWM_SET_INITIAL_DATA: 'EZWM_SET_INITIAL_DATA',

    //PRICE LIST MODULE
    PRICE_LIST_SET_ALL: 'PRICE_LIST_SET_ALL',

    //PHARMA
    REFRESH_DRUGS_TABLE_CHECKED_ARRAY: 'REFRESH_DRUGS_TABLE_CHECKED_ARRAY',

    //PROGRAMS
    PROGRAMS_POPULATION_CONFIG_SET_ALL: 'PROGRAMS_POPULATION_CONFIG_SET_ALL',
    PROGRAMS_POPULATION_EVENT_SET_ALL: 'PROGRAMS_POPULATION_EVENT_SET_ALL',
    PROGRAMS_POPULATION_PROFILE_SET_ALL: 'PROGRAMS_POPULATION_PROFILE_SET_ALL',
    PROGRAMS_POPULATION_SET_ACTIVE_PATH: 'PROGRAMS_POPULATION_SET_ACTIVE_PATH',
    PROGRAMS_POPULATION_SET_PATIENT_TICKETS: 'PROGRAMS_POPULATION_SET_PATIENT_TICKETS',
    PROGRAMS_POPULATION_ADD_EVENT_TO_IPOM: 'PROGRAMS_POPULATION_ADD_EVENT_TO_IPOM',
    PROGRAMS_POPULATION_RESET_IPOM: 'PROGRAMS_POPULATION_RESET_IPOM',
    PROGRAMS_POPULATION_EVENT_TO_IPOM_RESET: 'PROGRAMS_POPULATION_EVENT_TO_IPOM_RESET',

    //DILO
    DILO_ADD: 'DILO_ADD',
    DILO_REMOVE: 'DILO_REMOVE',
    DILO_UPDATE: 'DILO_UPDATE',
    DILO_CANCEL_ADD: 'DILO_CANCEL_ADD',
    DILO_CANCEL_REMOVE: 'DILO_CANCEL_REMOVE',
    DILO_RESET: 'DILO_RESET',

    //MED-QUESTIONS
    MED_QUESTION_GROUP_SET: 'MED_QUESTION_GROUP_SET',
    MED_QUESTIONS_CONFIG_SET: 'MED_QUESTIONS_CONFIG_SET',

    //RECOMMENDATIONS
    RECOMMENDATION_CONFIG_SET: 'RECOMMENDATION_CONFIG_SET',
    RECOMMENDATION_GOAL_SET: 'RECOMMENDATION_GOAL_SET',
    RECOMMENDATION_GOAL_GROUP_SET: 'RECOMMENDATION_GOAL_GROUP_SET',

    //PROGRAMS APPOINTMENT MODULE
    PROGRAM_APPOINTMENT_ADD_SUBUNIT_INFO: 'PROGRAM_APPOINTMENT_ADD_SUBUNIT_INFO',
    PROGRAM_APPOINTMENT_ADD_SUBJECT_INFO: 'PROGRAM_APPOINTMENT_ADD_SUBJECT_INFO',
    PROGRAM_APPOINTMENT_ADD_ORG_UNIT_INFO: 'PROGRAM_APPOINTMENT_ADD_ORG_UNIT_INFO',
    PROGRAM_APPOINTMENT_ADD_PROGRAM_ID: 'PROGRAM_APPOINTMENT_ADD_PROGRAM_ID',
    PROGRAM_APPOINTMENT_RESET: 'PROGRAM_APPOINTMENT_RESET',
    PROGRAM_APPOINTMENT_SET_FOCUS_APPOINTMENT_NURSE: 'PROGRAM_APPOINTMENT_SET_FOCUS_APPOINTMENT_NURSE',
    PROGRAM_APPOINTMENT_SET_ONGOING_APPOINTMENT: 'PROGRAM_APPOINTMENT_SET_ONGOING_APPOINTMENT',
    PROGRAM_APPOINTMENT_SET_APPOINTMENT_INITIAL_DATA: 'PROGRAM_APPOINTMENT_SET_APPOINTMENT_INITIAL_DATA',

    //IPOM MODULE
    PATIENT_IPOMS_SET: 'PATIENT_IPOMS_SET',
    PATIENT_IPOMS_RESET: 'PATIENT_IPOMS_RESET',
    PATIENT_IPOMS_SENT_ADD: 'PATIENT_IPOMS_SENT_ADD',
    PATIENT_IPOMS_SENT_REMOVE: 'PATIENT_IPOMS_SENT_REMOVE',
    PATIENT_IPOMS_SENT_SET: 'PATIENT_IPOMS_SENT_SET',
    PATIENT_IPOM_ADD_DRUG: 'PATIENT_IPOM_ADD_DRUG',
    PATIENT_IPOM_REMOVE_DRUG: 'PATIENT_IPOM_REMOVE_DRUG',

    PATIENT_HIPOMS_SET: 'PATIENT_HIPOMS_SET',
    PATIENT_HIPOMS_SENT_ADD: 'PATIENT_HIPOMS_SENT_ADD',
    PATIENT_HIPOMS_SENT_REMOVE: 'PATIENT_HIPOMS_SENT_REMOVE',

    //APKOLCE
    APKOLCE_SET_INITIAL: 'APKOLCE_SET_INITIAL',
    APKOLCE_SET_INITIAL_CONFIG: 'APKOLCE_SET_INITIAL_CONFIG',
    APKOLCE_SET_CHECK_ERROR_STATUS: 'APKOLCE_SET_CHECK_ERROR_STATUS',
    APKOLCE_SET_CHECK_ERROR: 'APKOLCE_SET_CHECK_ERROR',
    APKOLCE_SET_CHECK_LOGIN: 'APKOLCE_SET_CHECK_LOGIN',
    APKOLCE_SET_CHECK_WARNING: 'APKOLCE_SET_CHECK_WARNING'
};