import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'
import { Apis } from '../config_apis'
import axios from 'axios'

export default {
    namespaced: false,

    state: {
        // NEW NEW NEW FOR REGISTRATION STATE

        registrationFacility: null,

        ikz: [],

        autoNavigateTrigger: 0,
        autoNavigateTarget: null,

        /** Added eReferrals (read, accepted, cancelled) */
        eReferrals: [],
        eReferralsTrigger: 0,
        eReferralReading: false,

        translatedFiles: [],

        /** Added description */
        description: "",

        /** Added service */
        service: 0,

        /** Added statuses */
        statuses: [],

        /** Added declarations */
        declarations: [],

        /** Added relatives */
        relatives: [],

        /** Added entitlements */
        entitlements: [],

        /** Added incidents */
        incidents: [],

        /** Added notes */
        notes: [],

        /** Added issued results */
        issuedResults: [],

        /** Added notifications */
        notifications: [],

        /** Added health record proposals */
        healthRecordProposals: [],

        /** Added tickets */
        tickets: [],

        /** Added schedules */
        schedules: [],

        /** Sales made */
        sales: [],

        /** Informations provided */
        informations: [],

        /** Whether there is an eWUŚ check pending for patient */
        ewusCheckPending: false,

        /** eWUŚ state */
        ewusState: null,

        ewusStateNew: null,

        /** Wheather patient is new */
        newPatient: false,

        /** Patient info */
        patient: null,

        patientOriginal: null,

        /** Uploaded files */
        files: [],

        /** Patient privileges */
        privileges: [],

        patientVisits: [],

        /** Wheather patient made a declaration */
        declaration: false,

        /** Worker who made registration */
        worker: null,

        trigger: 0,

        activeBundle: null
    },

    mutations: {
        [Mutation.REGISTRATION_RESET](currentState, worker) {
            currentState.patient = null
            currentState.patientOriginal = null
            currentState.newPatient = false
            currentState.worker = null
            currentState.files.length = 0 // = []
            currentState.declaration = false
            currentState.schedules.length = 0 // = []
            currentState.sales = []
            currentState.ewusCheckPending = false
            currentState.ewusState = null
            currentState.ewusStateNew = null
            currentState.informations.length = 0 // = []
            currentState.worker = worker

            currentState.statuses.length = 0 // = []
            currentState.declarations.length = 0 // = []
            currentState.relatives.length = 0 // = []
            currentState.entitlements.length = 0 // = []
            currentState.incidents.length = 0 // = []
            currentState.notes.length = 0 // = []
            currentState.issuedResults.length = 0 // = []
            currentState.notifications.length = 0 // = []
            currentState.healthRecordProposals.length = 0 // = []
            currentState.tickets.length = 0 // = []
            currentState.eReferrals.length = 0
            currentState.eReferralsTrigger = 0
            currentState.eReferralReading = false
            currentState.description = ""
            currentState.service = 0
            currentState.privileges = []
            currentState.patientVisits = []
            currentState.translatedFiles = []
        },

        [Mutation.REGISTRATION_SET_TRANSLATED_FILES](currentState, payload) {
            currentState.translatedFiles = payload
        },

        [Mutation.SCHEDULE_SET_PATIENT_VISITS](currentState, payload) {
            currentState.patientVisits = payload
        },

        [Mutation.SCHEDULE_ADD_PATIENT_VISIT](currentState, payload) {

            if (currentState.patient && payload && currentState.patientVisits) {
                let existing = currentState.patientVisits.find(x => x.patientId == payload.patientId && x.id == payload.id)

                if (existing) {
                    let ind = currentState.patientVisits.indexOf(existing)
                    currentState.patientVisits.splice(ind, 1)
                }
                else {
                    currentState.patientVisits.push(payload)
                }
            }
        },

        [Mutation.REGISTRATION_AUTO_NAVIGATE](currentState, payload) {
            currentState.autoNavigateTarget = payload
            currentState.autoNavigateTrigger++
        },

        [Mutation.REGISTRATION_SET_PATIENT](currentState, payload) {
            currentState.patient = payload
            currentState.patientOriginal = JSON.parse(JSON.stringify(payload))
            currentState.ewusCheckPending = true
        },

        [Mutation.REGISTRATION_SET_EWUS](currentState, payload) {
            currentState.ewusState = payload
            currentState.ewusCheckPending = false
        },

        [Mutation.REGISTRATION_NULL_EWUS](currentState) {
            currentState.ewusState = null
            currentState.ewusCheckPending = false
        },

        [Mutation.REGISTRATION_SET_EWUS_NEW](currentState, payload) {
            currentState.ewusStateNew = payload
            currentState.ewusCheckPending = false
        },

        [Mutation.REGISTRATION_NULL_EWUS_NEW](currentState) {
            currentState.ewusStateNew = null
            currentState.ewusCheckPending = false
        },

        [Mutation.REGISTRATION_SET_WORKER](currentState, payload) {
            currentState.worker = payload
        },

        [Mutation.REGISTRATION_SET_EWUS_ON](currentState) {
            currentState.ewusCheckPending = true
        },

        [Mutation.REGISTRATION_SET_EWUS_OFF](currentState) {
            currentState.ewusCheckPending = false
        },

        [Mutation.REGISTRATION_ADD_SALE](currentState, payload) {
            let indexOdProduct = currentState.sales.findIndex(u => u.id === payload.id)

            if (indexOdProduct > -1) {
                if (payload.amount > 0) {
                    currentState.sales[indexOdProduct].amount = payload.amount
                }
                else {
                    currentState.sales[indexOdProduct].amount = 0
                }
            }
            else {
                currentState.sales.push(payload)
            }
            currentState.trigger++
        },

        [Mutation.REGISTRATION_SET_SALES](currentState, payload) {
            currentState.sales = payload
        },

        [Mutation.REGISTRATION_ADD_SCHEDULE](currentState, payload) {
            currentState.schedules.push(payload)
        },

        [Mutation.REGISTRATION_REMOVE_SCHEDULE](currentState, payload) {
            let indexOfSchedule = currentState.schedules.indexOf(payload)

            if (indexOfSchedule > -1) {
                currentState.schedules.splice(indexOfSchedule, 1)
            }
        },

        [Mutation.REGISTRATION_REMOVE_SCHEDULE_2](currentState, payload) {
            let app = currentState.schedules.find(app => app.id == payload)
            if (app) {
                let indexOfSchedule = currentState.schedules.indexOf(app)
                if (indexOfSchedule > -1) {
                    currentState.schedules.splice(indexOfSchedule, 1)
                }
            }
        },

        [Mutation.REGISTRATION_EDIT_SCHEDULE](currentState, payload) {
            let app = currentState.schedules.find(app => app.id == payload.id)

            if (app) {
                let indexOfSchedule = currentState.schedules.indexOf(app)
                if (indexOfSchedule > -1) {
                    let schedulesArray = JSON.parse(JSON.stringify(currentState.schedules))
                    schedulesArray[indexOfSchedule] = payload
                    currentState.schedules = schedulesArray
                }
            }
        },

        [Mutation.REGISTRATION_SET_SCHEDULE](currentState, payload) {
            currentState.schedules = payload
        },

        [Mutation.REGISTRATION_CLEAR_EREFERRALS](currentState) {
            currentState.eReferrals = []
            currentState.eReferralsTrigger = 0
            currentState.eReferralReading = false
        },

        [Mutation.REGISTRATION_ADD_IFORMATION](currentState, payload) {
            currentState.informations.push(payload)
        },

        [Mutation.REGISTRATION_REMOVE_IFORMATION](currentState, payload) {
            let indexOfInformation = currentState.informations.indexOf(payload)

            if (indexOfInformation > -1) {
                currentState.informations.splice(indexOfInformation, 1)
            }
        },

        [Mutation.REGISTRATION_REMOVE_ENTITLEMENT](currentState, payload) {
            let entitlement = currentState.entitlements.find(ent => ent.entitlementType == payload.entitlementType && ent.status == payload.status && ent.additionalEntitlementType == payload.additionalEntitlementType && ent.documentNumber == payload.documentNumber)
            if (entitlement) {
                let indexOfEnt = currentState.entitlements.indexOf(entitlement)

                if (indexOfEnt > -1) {
                    currentState.entitlements.splice(indexOfEnt, 1)
                }
            }
        },

        [Mutation.REGISTRATION_REMOVE_SALE](currentState, payload) {
            let indexOdProduct = currentState.sales.findIndex(u => u.id === payload.id)

            if (indexOdProduct > -1) {
                currentState.sales.splice(indexOdProduct, 1)
            }
        },

        // NEW NEW NEW FOR REGISTRATION STORE
        [Mutation.REGISTRATION_SET_STATUSES](currentState, payload) {
            currentState.statuses = payload
        },

        [Mutation.REGISTRATION_ADD_DECLARATION](currentState, payload) {
            currentState.declarations.push(payload)
        },

        [Mutation.REGISTRATION_ADD_RELATIVE](currentState, payload) {
            currentState.relatives.push(payload)
        },

        [Mutation.REGISTRATION_ADD_ENTITLEMENT](currentState, payload) {
            currentState.entitlements.push(payload)
        },

        [Mutation.REGISTRATION_SET_PATIENT_PRIVILEGES](currentState, payload) {
            if (payload) {
                currentState.privileges = JSON.parse(JSON.stringify(payload))
            }
            else {
                currentState.privileges = []
            }
        },

        [Mutation.REGISTRATION_ADD_INCIDENT](currentState, payload) {
            currentState.incidents.push(payload)
        },

        [Mutation.REGISTRATION_ADD_NOTE](currentState, payload) {
            currentState.notes.push(payload)
        },

        [Mutation.REGISTRATION_ADD_ISSUED_RESULT](currentState, payload) {
            currentState.issuedResults.push(payload)
        },

        [Mutation.REGISTRATION_ADD_NOTIFICATION](currentState, payload) {
            currentState.notifications.push(payload)
        },

        [Mutation.REGISTRATION_ADD_HEALTH_RECORD_PROPOSAL](currentState, payload) {
            currentState.healthRecordProposals.push(payload)
        },

        [Mutation.REGISTRATION_ADD_TICKET](currentState, payload) {
            currentState.tickets.push(payload)
        },

        [Mutation.REGISTRATION_SET_TICKET_STATUS](currentState, payload) {
            let findTicket = currentState.tickets.find(ticket => ticket.id === payload.id)
            if(findTicket) {
                findTicket.state = payload.state
            }
        },

        [Mutation.REGISTRATION_ADD_ACTIVE_BUNDLE](currentState, payload) {
            currentState.activeBundle = payload
        },

        [Mutation.REGISTRATION_SET_UNFINISHED_VISIT](currentState, payload) {
            currentState.statuses = payload && payload.statuses ? payload.statuses : []
            currentState.declarations = payload && payload.declarations ? payload.declarations : []
            currentState.relatives = payload && payload.relatives ? payload.relatives : []
            currentState.entitlements = payload && payload.entitlements ? payload.entitlements : []
            currentState.incidents = payload && payload.incidents ? payload.incidents : []
            currentState.notes = payload && payload.notes ? payload.notes : []
            currentState.issuedResults = payload && payload.issuedResults ? payload.issuedResults : []
            currentState.notifications = payload && payload.notifications ? payload.notifications : []
            currentState.healthRecordProposals = payload && payload.healthRecordProposals ? payload.healthRecordProposals : []
            currentState.tickets = payload && payload.tickets ? payload.tickets : []
            currentState.schedules = payload && payload.schedules ? payload.schedules : []
            currentState.sales = []
            currentState.informations = payload && payload.informations ? payload.informations : []
            currentState.eReferrals = payload && payload.eReferrals ? payload.eReferrals : []
            currentState.description = payload && payload.description ? payload.description : ""
            currentState.service = payload && payload.service ? payload.service : 0
        },

        [Mutation.REGISTRATION_EREFERRAL_READING](currentState, payload) {
            currentState.eReferralReading = payload
        },

        [Mutation.REGISTRATION_ADD_EREFERRAL](currentState, payload) {
            if (payload) {
                let present = currentState.eReferrals.find(eRef => eRef.key == payload.eSkierowanieKlucz)

                if (present) {
                    present.id = payload.id
                    present.code = payload.eSkierowanieKod
                    present.key = payload.eSkierowanieKlucz
                    // present.number = payload.eSkierowanieNumer
                    present.authorUnitCode = payload.authorUnitCode
                    present.p1Status = payload.statusSkierowaniaP1

                    currentState.eReferralsTrigger += 1
                } else {
                    let eReferral = {
                        id: payload.id,
                        code: payload.eSkierowanieKod,
                        key: payload.eSkierowanieKlucz,
                        number: payload.eSkierowanieNumer,
                        authorUnitCode: payload.authorUnitCode,
                        p1Status: payload.statusSkierowaniaP1
                    }
                    currentState.eReferrals.push(eReferral)
                    currentState.eReferralsTrigger += 1
                }
            }
        },

        [Mutation.REGISTRATION_SET_IKZ](currentState, payload) {
            currentState.ikz = payload
        },

        [Mutation.REGISTRATION_SET_PATIENT_TELEPHONE_VERIFIED](currentState, payload) {
            if (currentState.patient) {
                currentState.patient.teleAddressTelephoneConfirmed = payload.validated
                currentState.patient.version = payload.version
            }

            if (currentState.patientOriginal) {
                currentState.patientOriginal.teleAddressTelephoneConfirmed = payload.validated
                currentState.patientOriginal.version = payload.version
            }
        },

        [Mutation.REGISTRATION_SET_REGISTRATION_FACILITY](currentState, payload) {
            currentState.registrationFacility = payload
        },
    },

    actions: {
        [Action.REGISTRATION_RESET](context) {
            let worker = context.rootState.employee.me
            context.commit(Mutation.REGISTRATION_RESET, worker)
        },

        [Action.PD_ADD_BUNDLE](context, data) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PD_ADD_BUNDLE,
                        data: data,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PD_ADD_REGISTRATION_TO_BUNDLE](context, data) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PD_ADD_REGISTRATION_TO_BUNDLE.replace("{bundleId}", data.id).replace("{status}", data.status),
                        data: data.data,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PD_GET_UNFINISHED_REGISTRATIONS](context, workerId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PD_GET_UNFINISHED_REGISTRATIONS.replace("{workerId}", workerId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PD_GET_UNFINISHED_REGISTRATIONS_FACILITY](context, facility) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PD_GET_UNFINISHED_REGISTRATIONS_FACILITY.replace("{facility}", facility),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
    }
}