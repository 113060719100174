<template>
  <div>
    <ContentPanel icon="cog" title="Konfiguracja użytkownika" subtitle="dla rejestracji">
      <template slot="addon">
        <b-button
          icon-left="information-outline"
          size="is-small"
          type="is-primary"
          @click="mDownloadInstruction('671a40e49da2e8e65613f891')"
        >Instrukcja konfiguracji AP-KOLCE</b-button>
      </template>
      <b-message :type="colorMessage" has-icon>
        <div v-if="resultConfigStore">
          <div v-if="resultConfigStore.isGood">
            Użytkownik ma dodaną konfigurację AP-Kolce w SZOK
            <div
              v-if="isCheckPassword"
              style="font-size:0.8rem"
            >Sprawdzanie poprawności loginu i hasła w AP-Kolce...</div>
            <div
              v-if="isApkolceError && apkolceLoginError != null"
              style="font-size:0.8rem"
              v-html="apkolceLoginError"
            ></div>
            <div
              v-if="!isCheckPassword && !isApkolceError"
              style="font-size:0.8rem"
            >Login i hasło AP-Kolce poprawne</div>
            <div
              v-if="isApkolceWarning && apkolceLoginError != null"
              style="font-size:0.8rem"
              v-html="apkolceLoginError"
            ></div>
          </div>
          <div v-else>
            <b>Problem z konfiguracją użytkownika AP-Kolce w SZOK.</b>
            <p class="mt-3">{{resultConfigStore.message}}.</p>
          </div>
          <div class="mt-4 buttons is-pulled-right">
            <b-button icon-left="refresh" size="is-small" @click="getApkolce">Odśwież</b-button>
            <b-button
              icon-right="arrow-right-thick"
              type="is-primary"
              size="is-small"
              @click="proceedTo('my-account')"
            >
              Przejdź do
              <b>Moje konto</b>
            </b-button>
          </div>
        </div>
        <p v-else>Sprawdzanie konfiguracji...</p>
      </b-message>
    </ContentPanel>

    <b-modal :active.sync="isComponentModalActive" has-modal-card :destroy-on-hide="false" scroll="keep"
      :trap-focus="false"
      :auto-focus="false"
    >
      <ContentPanel title="" icon="" :paddingless="true" :marginless="true" :borderless="true">
        <div class="modal-card">
          <section class="modal-card-body w-100 pt-3 pl-3 pr-3">
            <b-message :type="modalType" has-icon>
              <b>Konfiguracja AP-KOLCE</b>
              <p class="mt-3">{{message != null && message.length > 0 ? `${message}.` : "Błąd konfiguracji AP-Kolce, sprawdź szczegóły w 'Moje konto'."}}</p>
            </b-message>
          </section>
          <footer class="modal-card-foot buttons is-right">
            <b-button @click="isComponentModalActive = false">
              Zamknij
            </b-button>
            <b-button @click="proceedTo('my-account')" type="is-info">
              Przejdź do panelu mojego konta
            </b-button>
          </footer>
        </div>
      </ContentPanel>
    </b-modal>
  </div>
</template>
  
<script>
import { Action } from "@/store/config_actions";
import { Mutation } from "@/store/config_mutations";
// import FontSizeSelector from "@/components/navigation/FontSizeSelectorButtons";
import CommonHelpers from "@/mixins/commons";

export default {
  name: "MyAccountApKolceBaner",
  components: {
  },
  mixins: [CommonHelpers],

  props: {
    isPrimary: { type: Boolean, required: false, default: false }
  },

  data: function () {
    return {
      isComponentModalActive: false,
      loading: false,
      settings: null,

      resultConfig: null,

      loadingAction: false,
      resultRequest: null,
      isError: false,
      errorMessage: null,
      status: {
        status: 1,
        date: new Date(),
      },

      modalType: 'is-info',
      modalMessage: "",
    };
  },

  mounted() {
    //this.settings = JSON.parse(JSON.stringify(this.mySettings));
    //this.getConfigs()
    if (!this.isInitialSet) {
      this.$store.commit(Mutation.APKOLCE_SET_INITIAL);
      this.getApkolce()
    }
  },

  computed: {
    username() {
      return this.$store.state.identity.session.name;
    },
    email() {
      return this.$store.state.identity.session.email;
    },
    roles() {
      return this.$store.state.identity.session.roles;
    },
    mySettings() {
      return this.$store.state.config.mySettings;
    },
    me() {
      return this.$store.state.employee.me;
    },

    //----- props for apkolce
    isInitialSet() {
      return this.$store.state.apkolce.isInitialSet;
    },

    resultConfigStore() {
      return this.$store.state.apkolce.resultConfig;
    },
    isApkolceError() {
      return this.$store.state.apkolce.isApkolceError;
    },
    isApkolceWarning() {
      return this.$store.state.apkolce.isApkolceWarning;
    },
    apkolceLoginError() {
      return this.$store.state.apkolce.apkolceLoginError;
    },
    isCheckPassword() {
      return this.$store.state.apkolce.isCheckPassword;
    },
    //--

    colorMessage() {
      if (this.resultConfigStore) {
        if (this.isCheckPassword) {
          return 'is-info'
        } else if (this.isApkolceError) {
          return 'is-danger'
        } else if (this.isApkolceWarning) {
          return 'is-warning'
        } else if (this.resultConfigStore.isGood) {
          return 'is-success'
        } else if (!this.resultConfigStore.isGood) {
          return 'is-danger'
        }
      }

      return 'is-info'
    }
  },

  methods: {

    showDialog(message, type = 'is-info') {
      this.isComponentModalActive = true
      this.modalMessage = message
      this.modalType = type
    },

    proceedTo(name) {
      this.$router.push({ name: name })
    },

    async getApkolce() {
      this.loading = true;
      let payload = {
        workerId: this.me?.id + "",
        domainId: this.me?.domainId + ""
      }
      this.$store.commit(Mutation.APKOLCE_SET_CHECK_ERROR_STATUS, false);
      this.clearError()
      this.resultConfig = null
      await this.$store
        .dispatch(Action.APKOLCE_WORKERCREDS_CHECK_WORKER_CONFIG, payload)
        .then((response) => {
          this.loading = false;
          if (response) {
            this.resultConfig = response;
            if (this.resultConfig.isGood) {
              this.checkPassword()
            } else {
              this.showDialog(this.resultConfig.message)
            }
          } else {
            this.resultConfig = null
          }
        })
        .catch((error) => {
          this.loading = false;
          this.resultConfig = {
            isGood: false,
            message: "Brak odpowiedzi z serwera"
          }
          this.$store.commit(Mutation.APKOLCE_SET_INITIAL_CONFIG, this.resultConfig);
          this.apiProblem(error);
        });
    },

    async checkPassword() {
      let isGood = false
      this.loadingAction = true;
      this.clearError();
      this.$store.commit(Mutation.APKOLCE_SET_CHECK_ERROR_STATUS, false);
      this.$store.commit(Mutation.APKOLCE_SET_CHECK_LOGIN, true);
      const payload = {
        workerId: this.me.id + "",
        domain: this.me.domainId + "",
      };

      try {
        const requestId = await this.sendRequest(payload);
        this.resultRequest = null;

        if (requestId) {
          this.resultRequest = await this.getFinalResult(requestId);
          if (this.resultRequest != null) {
            if (this.resultRequest.apkolceStatus == 3) {
              isGood = true

              if (this.resultRequest.apkolceLoginMessage != null) {
                this.isError = true;
                this.showDialog(this.resultRequest.apkolceLoginMessage, 'is-warning')
                this.$store.commit(Mutation.APKOLCE_SET_CHECK_WARNING, this.resultRequest.apkolceLoginMessage);
              } else {
                this.isError = false;
                this.$store.commit(Mutation.APKOLCE_SET_CHECK_ERROR_STATUS, false);
              }
            } else {
              this.errorMessage = this.getErrorMessage(true);
              this.isError = true;
              this.$store.commit(Mutation.APKOLCE_SET_CHECK_ERROR, this.errorMessage);
            }
          } else {
            this.errorMessage = "Błąd sprawdzania";
            this.isError = true;
            this.$store.commit(Mutation.APKOLCE_SET_CHECK_ERROR, this.errorMessage);
          }
        }
      } catch (error) {
        this.errorMessage = "Błąd przetwarzania zapytania";
        this.isError = true;
        this.$store.commit(Mutation.APKOLCE_SET_CHECK_ERROR, this.errorMessage);
      } finally {
        this.loadingAction = false;
        this.$store.commit(Mutation.APKOLCE_SET_CHECK_LOGIN, false);
      }

      if (!isGood) {
        this.showDialog()
      }

      return isGood;
    },

    sendRequest(payload) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(Action.APKOLCE_ACTION_CHECK_PASSWORD, payload)
          .then((response) => {
            this.status.status = 1;
            this.status.date = new Date();
            resolve(response);
          })
          .catch((error) => {
            this.apiProblem(error);
            reject(error);
          });
      });
    },

    getFinalResult(id) {
      return new Promise((resolve, reject) => {
        let triesLeft = 15;
        const interval = setInterval(async () => {
          let result = await this.getResult(id);

          if (result != null) {
            this.status.status = result.apkolceStatus;
            this.status.date = result.apkolceStatusChange;
          }

          if (result != null && this.isEndResult(result.apkolceStatus)) {
            clearInterval(interval);
            resolve(result);
          } else if (triesLeft <= 1) {
            reject(result);
            clearInterval(interval);
          }
          triesLeft--;
        }, 5000);
      });
    },

    getResult(id) {
      //
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(Action.APKOLCE_ACTION_GET_RESULT, id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            this.apiProblem(error);
            reject(error);
          });
      });
    },

    getErrorMessage(joinStatus) {
      if (this.resultRequest) {
        let message = "";
        if (joinStatus) {
          message = `[${this.resultRequest.apkolceStatus
            }] ${this.getStatus(
              this.resultRequest.apkolceStatus
            )} ${this.mDateTime(this.resultRequest.apkolceStatusChange)}`;
        }

        if (
          this.resultRequest.apkolceErrorMessage != null &&
          this.resultRequest.apkolceErrorMessage.length > 0
        ) {
          if (joinStatus) message += `<br />`
          message += `${this.resultRequest.apkolceErrorMessage}`;
        }

        return message;
      } else {
        return "Błąd";
      }
    },

    clearError() {
      this.isError = false;
      this.errorMessage = null;
    },

    getStatus(stat) {
      switch (stat) {
        case 0:
          return "Brak informacji";
        case 1:
          return "Kolejka";
        case 2:
          return "Przetwarzanie";
        case 3:
          return "Ukończono";
        case 4:
          return "Błąd";
        case 5:
          return "Utworzono zawartość";
        case 6:
          return "Podpisano zawartość";
        case 7:
          return "Nie znaleziono pracownika";
        case 8:
          return "Wysłano zawartość";
        case 9:
          return "Błąd logowania";
        default:
          return "Brak informacji";
      }
    },

    isEndResult(status) {
      if (
        status == 7 ||
        status == 3 ||
        status == 4 ||
        status == 9
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
  